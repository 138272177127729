import { FixedSizeGrid, GridChildComponentProps } from "react-window";
import { ComponentType, useRef } from "react";
import useTundraStore from "../../store/useTundraStore";
import { EmissionsContainer, ScaleContainer } from "./EmissionsCalendar";
import { BASIC_HEIGHT } from "../../constants";
import Scale from "../Scale/Scale";
import AutoSizer from "react-virtualized-auto-sizer";
import { StartOfDay } from "../../types/StartOfDay";

const EmissionsWithScale = ({
  numberOfItems,
  itemWidth,
  children,
  startOfDay,
}: {
  numberOfItems: number;
  itemWidth: number;
  children: ComponentType<GridChildComponentProps>;
  startOfDay: StartOfDay;
}) => {
  const zoomLevel = useTundraStore((state) => state.zoomLevel);
  const basicHeight = (BASIC_HEIGHT * zoomLevel) / 100;

  const scaleRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <ScaleContainer>
        <Scale startOfDay={startOfDay} ref={scaleRef} />
      </ScaleContainer>
      <EmissionsContainer>
        <AutoSizer>
          {({ width, height }: any) => (
            <FixedSizeGrid
              columnCount={numberOfItems}
              columnWidth={itemWidth}
              rowCount={1}
              rowHeight={basicHeight * 24}
              height={height || 0}
              width={width || 0}
              onScroll={({ scrollTop }) => {
                if (scaleRef.current) {
                  scaleRef.current.style.transform = `translateY(${-scrollTop}px)`;
                }
              }}
            >
              {children}
            </FixedSizeGrid>
          )}
        </AutoSizer>
      </EmissionsContainer>
    </>
  );
};

export default EmissionsWithScale;
