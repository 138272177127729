import Dialog from "@mui/material/Dialog";
import { Button, DialogActions, DialogTitle, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const FilterDeleteModal = ({
  isOpen,
  onClose,
  onSubmit,
  filterToDelete,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (id: number) => void;
  filterToDelete: [number, string];
}) => {
  return (
    <>
      <Dialog onClose={onClose} open={isOpen}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {`Delete filter "${filterToDelete[1]}"?`}
          <IconButton onClick={onClose}>
            <Close sx={{ color: "primary.main", fontSize: "22px" }} />
          </IconButton>
        </DialogTitle>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              onSubmit(filterToDelete[0]);
            }}
          >
            Delete
          </Button>
          <Button
            variant="text"
            color="secondary"
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FilterDeleteModal;
