import { TundraFilters } from "../store/useTundraStore";
import { SerializedFilter } from "../types/SerializedFilter";
import dayjs from "dayjs";

export const serializeFilters = (filters: TundraFilters): SerializedFilter => {
  return {
    startDate: dayjs(filters.pickerStartDate).format("YYYY-MM-DD"),
    endDate: dayjs(filters.pickerEndDate).format("YYYY-MM-DD"),
    organizeBy: filters.organizeBy,
    startOfDay: filters.startOfDay,
    titleId: filters.titleId,
    classifiersSports: filters.classifiersSports,
    classifiersEvents: filters.classifiersEvents,
    classifiersCompetitions: filters.classifiersCompetitions,
    countries: filters.countries,
    searchType: filters.searchType,
    searchString: filters.searchString,
    broadcastTypes: filters.broadcastTypes,
    showAffiliates: filters.showAffiliates,
    uiChannels: filters.uiChannels,
  };
};
