import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { StartOfDay } from "../../types/StartOfDay";

const MidnightToggle = ({
  value,
  onChange,
}: {
  value: StartOfDay;
  onChange: (startOfDay: StartOfDay) => void;
}) => {
  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={(event, value) => value && onChange(value)}
    >
      <ToggleButton value="midnight">Start&nbsp;at&nbsp;midnight</ToggleButton>
      <ToggleButton value="3am">Start&nbsp;at&nbsp;3am</ToggleButton>
    </ToggleButtonGroup>
  );
};

export default MidnightToggle;
