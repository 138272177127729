import { GridProgram } from "../../types/GridProgram";

export const isGridProgramDeactivated = (
  program: GridProgram,
  searchString: string,
  searchType: string,
  broadcastTypes: number[]
) => {
  if (!broadcastTypes.includes(program.broadcastId)) {
    return true;
  }

  if (searchString !== undefined && searchString.length > 0) {
    const s = searchString.toLowerCase();
    const title = program.title.toLowerCase();
    const comment = (program.comment || "").toLowerCase();
    const firstBroadcastChannel =
      program.firstBroadcastChannelTwoChar.toLowerCase();

    if (
      searchType === "all" &&
      !(
        title.includes(s) ||
        comment.includes(s) ||
        firstBroadcastChannel.includes(s)
      )
    ) {
      return true;
    }

    if (searchType === "comment" && !comment.includes(s)) {
      return true;
    }

    if (searchType === "firstBroadcast" && !firstBroadcastChannel.includes(s)) {
      return true;
    }

    if (searchType === "title" && !title.includes(s)) {
      return true;
    }
  }

  return false;
};
