import { Box, CircularProgress } from "@mui/material";
import { ChannelType } from "../../types/ChannelType";
import useChannels from "../../hooks/useChannels";
import getChannelByAffiliateId from "../../utils/getChannelByAffiliateId";
import { UIChannel } from "../../types/UIChannel";
import { Channel } from "../../types/Channel";
import getChannelById from "../../utils/getChannelById";
import ChannelPicker from "../ChannelPicker/ChannelPicker";

const AffiliatePicker = ({
  value,
  onChange,
}: {
  value: UIChannel[];
  onChange: (uiChannels: UIChannel[]) => void;
}) => {
  const channelsQuery = useChannels();

  if (channelsQuery.isLoading) {
    return <CircularProgress />;
  }

  if (channelsQuery.isError || !channelsQuery.data) {
    return <>Error loading channels</>;
  }

  const selectedChannels: Channel[] = [];

  for (let uiChannel of value) {
    if (uiChannel.type === ChannelType.Affiliate) {
      const channel = getChannelByAffiliateId(
        uiChannel.id,
        uiChannel.slotId,
        channelsQuery.data.affiliatesAsChannels
      );
      if (channel) {
        selectedChannels.push(channel);
      }
    }
  }

  return (
    <Box sx={{ display: "flex", minWidth: "150px" }}>
      <ChannelPicker
        channels={channelsQuery.data.affiliatesAsChannels}
        channelsSelected={selectedChannels.map(({ id }) => id)}
        setSelected={(ids: number[]) => {
          const selected: UIChannel[] = [];
          for (let id of ids) {
            const channel = getChannelById(
              id,
              channelsQuery.data.affiliatesAsChannels
            );
            if (
              channel &&
              channel.affiliateId !== undefined &&
              channel.slotId !== undefined
            ) {
              selected.push({
                type: ChannelType.Affiliate,
                id: channel.affiliateId,
                slotId: channel.slotId,
              });
            }
          }
          onChange(selected);
        }}
        label="Affiliates"
        tabs={[
          {
            label: "Affiliates",
            typeId: 1,
            searchLabel: "Search affiliates...",
            allowSelectingOnTopLevel: false,
          },
        ]}
      />
    </Box>
  );
};

export default AffiliatePicker;
