import { Channel } from "../types/Channel";
import { UIChannel } from "../types/UIChannel";
import { Affiliate } from "../types/Affiliate";
import { ChannelType } from "../types/ChannelType";
import getChannelById from "./getChannelById";

const getChannelLabel = (
  channel: UIChannel,
  channels: { channels: Channel[]; affiliates: Affiliate[] }
): string => {
  if (channel.type === ChannelType.Channel) {
    const channelItem = getChannelById(channel.id, channels.channels);
    return channelItem?.name || `${channel.id}}`;
  } else {
    const affiliate = channels.affiliates.find(
      (item) => item.id === channel.id
    );
    if (affiliate) {
      return `${affiliate.name} — ${channel.slotId}`;
    }
    return `${channel.id} — ${channel.slotId}`;
  }
};

export default getChannelLabel;
