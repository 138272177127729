import { SVGProps } from "react";

const ReliveIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M12 0c6.628 0 12 5.372 12 12s-5.372 12-12 12a11.97 11.97 0 0 1-6.858-2.152c-.68-.515-.846-1.41-.37-2.09a1.497 1.497 0 0 1 2.086-.37A8.953 8.953 0 0 0 12 21c4.969 0 9-4.031 9-9 0-5.01-4.031-9-9-9a8.908 8.908 0 0 0-6.366 2.634l1.444 1.444C7.786 7.786 7.284 9 6.286 9H1.125A1.124 1.124 0 0 1 0 7.875v-5.16c0-1.001 1.212-1.503 1.92-.795l1.595 1.595A11.944 11.944 0 0 1 11.958 0H12Zm0 6c.623 0 1.125.502 1.125 1.125v4.41l3.005 3.043c.478.44.478 1.153 0 1.552-.399.478-1.111.478-1.552 0l-3.375-3.375c-.21-.17-.328-.455-.328-.755V7.125C10.875 6.502 11.377 6 12 6Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default ReliveIcon;
