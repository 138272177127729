import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import { DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Close, HelpOutline } from "@mui/icons-material";

const ScheduleHelp = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={() => setIsModalOpen(true)}
        sx={(theme) => ({
          color: "white",
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        })}
      >
        <HelpOutline />
      </IconButton>
      {isModalOpen && (
        <Dialog open={true} onClose={onClose} maxWidth="xl" fullWidth>
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Tundra Help
            <IconButton onClick={onClose}>
              <Close sx={{ color: "primary.main", fontSize: "22px" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <embed
              src="/readme.pdf#toolbar=0&navpanes=0&scrollbar=0"
              width="100%"
              height="100%"
              style={{ border: "none", minHeight: "75vh" }}
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default ScheduleHelp;
