import useTundraStore from "../../store/useTundraStore";
import BroadcastTypesPicker from "./BroadcastTypesPicker";

const ConnectedBroadcastTypesPicker = () => {
  const broadcastTypes = useTundraStore((state) => state.broadcastTypes);
  const setBroadcastTypes = useTundraStore((state) => state.setBroadcastTypes);
  return (
    <BroadcastTypesPicker value={broadcastTypes} onChange={setBroadcastTypes} />
  );
};

export default ConnectedBroadcastTypesPicker;
