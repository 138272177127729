import { useQuery } from "@tanstack/react-query";
import { Change } from "../types/Change";
import constructParametersForChangesRequest from "../utils/constructParametersForChangesRequest";

const CHANGES_API_URL = process.env.REACT_APP_CHANGES_API as string;

type ChangesResponse = {
  histoChanges: [Change];
};

const getChanges = (parameters: {}): Promise<ChangesResponse> => {
  return fetch(CHANGES_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(parameters),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
};

const useChanges = (
  startDate: string,
  endDate: string,
  channelIds: number[],
  typesOfChanges: number[],
  broadcastTypeIds: number[]
) => {
  return useQuery({
    queryKey: [
      "changes",
      startDate,
      endDate,
      channelIds.join(","),
      typesOfChanges.join(","),
      broadcastTypeIds.join(","),
    ],
    queryFn: () =>
      getChanges(
        constructParametersForChangesRequest(
          startDate,
          endDate,
          channelIds,
          typesOfChanges,
          broadcastTypeIds
        )
      ),
  });
};

export default useChanges;
