import { SVGProps } from "react";

const LiveIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.18 3.133a1.09 1.09 0 0 0-1.541-.015A12.337 12.337 0 0 0 0 11.882a12.342 12.342 0 0 0 3.906 9.023 1.087 1.087 0 0 0 1.515-.038c.474-.474.415-1.228-.04-1.665a10.095 10.095 0 0 1-3.131-7.32c0-2.769 1.112-5.28 2.915-7.107.434-.442.48-1.177.015-1.642ZM7.82 5.777a1.071 1.071 0 0 0-1.512-.024 8.6 8.6 0 0 0-2.559 6.13 8.595 8.595 0 0 0 2.84 6.395 1.07 1.07 0 0 0 1.474-.055c.492-.492.4-1.266-.062-1.701A6.356 6.356 0 0 1 6 11.882c0-1.725.686-3.29 1.8-4.44.429-.44.5-1.19.021-1.666v.001Zm8.358 0a1.07 1.07 0 0 1 1.512-.024 8.599 8.599 0 0 1 2.559 6.13 8.594 8.594 0 0 1-2.84 6.395 1.07 1.07 0 0 1-1.474-.055c-.492-.492-.4-1.266.062-1.701A6.356 6.356 0 0 0 18 11.882a6.357 6.357 0 0 0-1.8-4.44c-.429-.44-.5-1.19-.021-1.666v.001Zm2.642-2.643a1.088 1.088 0 0 1 1.54-.015A12.338 12.338 0 0 1 24 11.882a12.342 12.342 0 0 1-3.906 9.023 1.087 1.087 0 0 1-1.515-.038c-.474-.474-.416-1.228.042-1.665a10.095 10.095 0 0 0 3.129-7.32c0-2.769-1.111-5.28-2.915-7.107-.434-.442-.48-1.177-.015-1.642v.001ZM12 9.632a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5Z"
      fill="white"
    />
  </svg>
);

export default LiveIcon;
