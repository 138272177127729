import { ScheduleProgram } from "../../types/ScheduleProgram";

export const isScheduleProgramDeactivated = (
  program: ScheduleProgram,
  sports: number[],
  events: number[],
  competitions: number[],
  searchType: string,
  searchString: string | undefined,
  broadcastTypes: number[],
  countries: number[]
) => {
  if (sports.length > 0 && !sports.includes(program.sportId)) return true;

  if (events.length > 0 && !events.includes(program.eventId)) return true;

  if (competitions.length > 0 && !competitions.includes(program.competitionId))
    return true;

  if (
    countries.length > 0 &&
    !program.countryIds.some((id) => countries.includes(id))
  )
    return true;

  if (!broadcastTypes.includes(program.broadcastTypeId)) {
    return true;
  }

  if (searchString !== undefined && searchString.length > 0) {
    const s = searchString.toLowerCase();
    const title = program.title.toLowerCase();
    const feature = program.feature.toLowerCase();
    const description = program.description.toLowerCase();
    const sport = program.sport?.toLowerCase() || "";
    const competition = program.competition?.toLowerCase() || "";
    const event = program.event?.toLowerCase() || "";

    if (
      searchType === "all" &&
      !(
        title.includes(s) ||
        feature.includes(s) ||
        description.includes(s) ||
        event.includes(s) ||
        sport.includes(s)
      )
    ) {
      return true;
    }

    if (searchType === "feature" && !feature.includes(s)) {
      return true;
    }

    if (searchType === "title" && !title.includes(s)) {
      return true;
    }

    if (searchType === "sport" && !sport.includes(s)) {
      return true;
    }

    if (searchType === "competition" && !competition.includes(s)) {
      return true;
    }

    if (searchType === "event" && !event.includes(s)) {
      return true;
    }
  }

  return false;
};
