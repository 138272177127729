export const INNER_BORDER = "#D9D9D9";
export const INNER_BORDER_LIGHT = "#F3F2F2";

export const CALENDAR_REPLAY = "#D6EFFA";
export const CALENDAR_REPLAY_ACCENT = "#0369A1";

export const CALENDAR_LIVE = "#FCEBEF";
export const CALENDAR_LIVE_ACCENT = "#D50032";

export const CALENDAR_PROGRAM = "#F4FAED";
export const CALENDAR_PROGRAM_ACCENT = "#6BAE16";

export const CALENDAR_RELIVE = "#FFF3ED";
export const CALENDAR_RELIVE_ACCENT = "#FF671F";

export const CALENDAR_UNKNOWN = "#FAFAFA";
export const CALENDAR_UNKNOWN_ACCENT = "#D9D9D9";

export const GRID_FONT_RED = "#D50032";
export const GRID_FONT_BLACK = "#000000";
export const GRID_FONT_BLUE = "#3F80E1";
export const GRID_FONT_GREEN = "#4B7A0F";
export const GRID_BACKGROUND_GREEN = "#F3F9EC";

export const GRID_BACKGROUND_GRAY = "#FAFAFA";
export const GRID_BACKGROUND_WHITE = "#FFFFFF";
export const GRID_BACKGROUND_ROSE = "#FCEBEF";
