import useTundraStore from "../../store/useTundraStore";
import AffiliatesToggle from "./AffiliatesToggle";

const ConnectedAffiliatesToggle = () => {
  const showAffiliates = useTundraStore((state) => state.showAffiliates);
  const setShowAffiliates = useTundraStore((state) => state.setShowAffiliates);

  return (
    <AffiliatesToggle value={showAffiliates} onChange={setShowAffiliates} />
  );
};

export default ConnectedAffiliatesToggle;
