import { useQuery } from "@tanstack/react-query";
import { Broadcast } from "../types/Broadcast";

const BROADCASTS_API_URL = process.env.REACT_APP_BROADCASTS_API as string;

const getBroadcasts = (): Promise<Broadcast[]> => {
  return fetch(BROADCASTS_API_URL).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
};

const useBroadcasts = () => {
  return useQuery({
    queryKey: ["broadcastsData"],
    cacheTime: 24 * 60 * 1000,
    queryFn: () => getBroadcasts(),
  });
};

export default useBroadcasts;
