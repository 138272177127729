import { Box } from "@mui/material";
import Tooltip from "../Tooltip/Tooltip";

const EventIcon = ({
  background,
  Icon,
  tooltip = "",
  isActive = false,
}: {
  background: string;
  Icon: React.FunctionComponent;
  tooltip?: string;
  isActive?: boolean;
}) => {
  return (
    <Tooltip title={tooltip}>
      <Box
        sx={{
          borderRadius: "50%",
          backgroundColor: background,
          width: isActive ? 20 : 18,
          margin: isActive ? "-1px -1px 0 0" : 0,
          height: isActive ? 20 : 18,
          padding: "2.5px 0 0 3px",

          svg: {
            scale: "0.5",
            transformOrigin: "left top",
          },
          border: isActive ? "1px solid white" : "none",
        }}
      >
        <Icon />
      </Box>
    </Tooltip>
  );
};

export default EventIcon;
