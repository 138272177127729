import { useQuery } from "@tanstack/react-query";
import { Title } from "../types/Title";

const TITLES_API_URL = process.env.REACT_APP_TITLES_API as string;

const getTitles = (): Promise<Title[]> => {
  return fetch(TITLES_API_URL)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return res.json();
    })
    .then((titles) =>
      titles.filter((title: Title) => title.steName !== "FORECAST")
    );
};

const useTitles = () => {
  return useQuery({
    queryKey: ["titlesData"],
    cacheTime: 24 * 60 * 1000,
    queryFn: () => getTitles(),
  });
};

export default useTitles;
