import useTundraStore from "../../store/useTundraStore";
import RegularChannelsPicker from "./RegularChannelsPicker";

const ConnectedRegularChannelsPicker = () => {
  const uiChannels = useTundraStore((state) => state.uiChannels);
  const setUIChannels = useTundraStore((state) => state.setUIChannels);
  return <RegularChannelsPicker value={uiChannels} onChange={setUIChannels} />;
};

export default ConnectedRegularChannelsPicker;
