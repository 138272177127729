import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";

dayjs.extend(advancedFormat);

const formatDay = (date: string) => {
  return dayjs(date).format("ddd D MMM");
};

export default formatDay;
