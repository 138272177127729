import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import useLanguages from "../../hooks/useLanguages";
import { Cancel, ChatOutlined, Close } from "@mui/icons-material";
import getChannelById from "../../utils/getChannelById";
import useChannels from "../../hooks/useChannels";
import { UIChannel, UIClassicChannel } from "../../types/UIChannel";
import { ChannelType } from "../../types/ChannelType";
import { Language } from "../../types/Language";
import { Channel } from "../../types/Channel";

const getLanguageById = (id: number, languages: Language[]) => {
  return languages.find((language) => language.key === id);
};

const getLanguagesOptions = (
  defaultLanguage: number,
  languageOptions: number[],
  languages: Language[]
) => {
  const defaultLanguageOption = getLanguageById(defaultLanguage, languages) || {
    key: 0,
    value: "INTER",
  };
  const languageOptionsOptions = languageOptions
    .map((languageOption) => getLanguageById(languageOption, languages))
    .filter((languageOption): languageOption is Language => !!languageOption);
  if (languageOptionsOptions.length === 0) {
    return [defaultLanguageOption];
  }
  if (languageOptions.includes(defaultLanguage)) {
    return languageOptionsOptions;
  }
  return [defaultLanguageOption, ...languageOptionsOptions];
};

const LanguagePickerDialog = ({
  onClose,
  languages,
  channels,
  value,
  onChange,
}: {
  onClose: () => void;
  languages: Language[];
  channels: Channel[];
  value: UIChannel[];
  onChange: (uiChannels: UIChannel[]) => void;
}) => {
  const [uiChannels, setUIChannels] = useState(value);

  const uiClassicChannels = uiChannels.filter(
    (uiChannel) => uiChannel.type === ChannelType.Channel
  ) as UIClassicChannel[];

  return (
    <Dialog onClose={() => onClose()} open={true} maxWidth="xs" fullWidth>
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Languages
        <IconButton onClick={onClose}>
          <Close sx={{ color: "primary.main", fontSize: "22px" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Stack gap="8px">
          {uiClassicChannels.length === 0 && (
            <Typography color="text.secondary">No channels selected</Typography>
          )}
          {uiClassicChannels.map((uiChannel) => {
            const channel = getChannelById(uiChannel.id, channels);
            if (!channel) return null;
            return (
              <Stack direction="row" alignItems="center" gap="8px">
                <Box width="200px" overflow="hidden" textOverflow="ellipsis">
                  <Typography fontSize="12px">{channel?.name}</Typography>
                </Box>
                <Select
                  size="small"
                  sx={{ width: "120px" }}
                  value={uiChannel.languageId}
                  onChange={(e) => {
                    setUIChannels(
                      uiChannels.map((uiChannel) => {
                        if (uiChannel.id === channel?.id) {
                          return {
                            ...uiChannel,
                            languageId: e.target.value as number,
                          };
                        }
                        return uiChannel;
                      })
                    );
                  }}
                >
                  {getLanguagesOptions(
                    channel.defaultLanguageId || 0,
                    channel.channelEPGLanguageId || [],
                    languages
                  ).map((language) => (
                    <MenuItem value={language.key}>{language.value}</MenuItem>
                  ))}
                </Select>
              </Stack>
            );
          })}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "space-between" }}>
        <Button
          variant="text"
          color="secondary"
          onClick={() => {
            setUIChannels(
              uiChannels.map((uiChannel) => {
                if (uiChannel.type === ChannelType.Channel) {
                  const channel = getChannelById(uiChannel.id, channels);
                  if (!channel) return uiChannel;
                  return {
                    ...uiChannel,
                    languageId: channel.defaultLanguageId || 0,
                  };
                }
                return uiChannel;
              })
            );
          }}
          startIcon={<Cancel />}
        >
          Reset
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            onChange(uiChannels);
            onClose();
          }}
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const LanguagePicker = ({
  value,
  onChange,
}: {
  value: UIChannel[];
  onChange: (uiChannels: UIChannel[]) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const languagesQuery = useLanguages();
  const channelsQuery = useChannels();

  if (languagesQuery.isLoading || channelsQuery.isLoading)
    return <CircularProgress />;

  if (!languagesQuery.data) return <>Error loading Languages</>;

  if (!channelsQuery.data) return <>Error loading Channels</>;

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        variant="filter"
        sx={{
          minWidth: "140px",
          backgroundColor: "background.default",
        }}
        startIcon={<ChatOutlined />}
      >
        Languages
      </Button>
      {isOpen && (
        <LanguagePickerDialog
          onClose={() => setIsOpen(false)}
          languages={languagesQuery.data}
          channels={channelsQuery.data.channels}
          value={value}
          onChange={onChange}
        />
      )}
    </>
  );
};

export default LanguagePicker;
