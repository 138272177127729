import useTundraStore from "../../store/useTundraStore";
import { differenceInMinutes, sub } from "date-fns";
import { ScheduleEvent } from "./ScheduleEvent";
import { BASIC_HEIGHT } from "../../constants";
import { StartOfDay } from "../../types/StartOfDay";
import TimeIndicator from "./TimeIndicator";
import { Program } from "../../types/Program";
import { ProgramType } from "../../types/ProgramType";
import isScheduleProgram from "../../utils/isScheduleProgram";
import isGridProgram from "../../utils/isGridProgram";
import { GridEvent } from "./GridEvent";
import { isScheduleProgramDeactivated } from "./isScheduleProgramDeactivated";
import { isGridProgramDeactivated } from "./isGridProgramDeactivated";
import { styled } from "@mui/material/styles";

const Container = styled("div")`
  width: 100%;
  position: relative;

  * {
    transition: top 200ms ease-out;
  }
`;

const Hour = styled("div")(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  width: "100%",
  position: "relative",

  "&:before": {
    content: '" "',
    display: "block",
    position: "absolute",
    top: "50%",
    left: 0,
    right: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const getPositionAndHeight = (
  basicHeight: number,
  startDate: Date,
  endDate: Date,
  startOfDay: StartOfDay
): [number, number] => {
  const normalizedStartDate =
    startOfDay === "3am" ? sub(startDate, { hours: 3 }) : startDate;
  const top =
    (normalizedStartDate.getUTCHours() +
      normalizedStartDate.getUTCMinutes() / 60) *
    basicHeight;

  const normalizedEndDate =
    startOfDay === "3am" ? sub(endDate, { hours: 3 }) : endDate;

  const height =
    (differenceInMinutes(normalizedEndDate, normalizedStartDate) / 60) *
    basicHeight;
  return [top, height];
};

type DayProps = {
  programs: Program[];
  startOfDay: StartOfDay;
  date: string;
  gridDisplay?: number[];
};

const Day = ({ programs, startOfDay, date, gridDisplay }: DayProps) => {
  const zoomLevel = useTundraStore((state) => state.zoomLevel);
  const classifiersSports = useTundraStore((state) => state.classifiersSports);
  const classifiersEvents = useTundraStore((state) => state.classifiersEvents);
  const classifiersCompetitions = useTundraStore(
    (state) => state.classifiersCompetitions
  );
  const countries = useTundraStore((state) => state.countries);
  const searchType = useTundraStore((state) => state.searchType);
  const searchString = useTundraStore((state) => state.searchString);

  const broadcastTypes = useTundraStore((state) => state.broadcastTypes);

  const basicHeight = (BASIC_HEIGHT * zoomLevel) / 100;
  return (
    <Container style={{ height: basicHeight * 24 }}>
      <TimeIndicator date={date} startOfDay={startOfDay} />
      {Array.from(Array(24).keys()).map((key) => (
        <Hour style={{ height: basicHeight }} key={key} />
      ))}
      {programs.map((program) => {
        const [top, height] = getPositionAndHeight(
          basicHeight,
          program.startDate,
          program.endDate,
          startOfDay
        );
        if (isScheduleProgram(program)) {
          return (
            <ScheduleEvent
              key={program.key}
              program={program}
              top={top + 1}
              height={height - 2}
              deactivated={
                program.type === ProgramType.Schedule &&
                isScheduleProgramDeactivated(
                  program,
                  classifiersSports,
                  classifiersEvents,
                  classifiersCompetitions,
                  searchType,
                  searchString,
                  broadcastTypes,
                  countries
                )
              }
            />
          );
        } else if (isGridProgram(program)) {
          return (
            <GridEvent
              key={program.key}
              program={program}
              top={top + 1}
              height={height - 2}
              gridDisplay={gridDisplay || []}
              deactivated={
                program.type === ProgramType.Grid &&
                isGridProgramDeactivated(
                  program,
                  searchString,
                  searchType,
                  broadcastTypes
                )
              }
            />
          );
        } else {
          return null;
        }
      })}
    </Container>
  );
};

export default Day;
