import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { ChangesSubscription } from "../../../types/ChangesSubscription";
import useChannels from "../../../hooks/useChannels";
import getChannelById from "../../../utils/getChannelById";
import { Typography } from "@mui/material";
import useBroadcasts from "../../../hooks/useBroadcasts";
import { TYPES_OF_CHANGE } from "../../../components/TypeOfChangePicker/TypeOfChangePicker";

const ChangesSubscriptionsTable = ({
  data,
  onSelect,
  onDelete,
}: {
  data: ChangesSubscription[];
  onSelect: (data: ChangesSubscription) => void;
  onDelete: (id: number) => void;
}) => {
  const channelsQuery = useChannels();
  const broadcastsQuery = useBroadcasts();

  const columns: GridColDef[] = [
    { field: "title", headerName: "Title", width: 150 },
    { field: "email", headerName: "Email", width: 150 },
    { field: "range", headerName: "Range", width: 60 },
    {
      field: "channelIds",
      headerName: "Channels",
      width: 230,
      renderCell: (params: GridRenderCellParams<ChangesSubscription>) => {
        const ids = params.value || [];
        let channelNames = ids.join(", ");
        if (channelsQuery.data) {
          channelNames = ids
            .map((id: number) => {
              const channel = getChannelById(id, channelsQuery.data.channels);
              return channel?.name || `${id}`;
            })
            .join(", ");
        }

        return (
          <Typography overflow="hidden" textOverflow="ellipsis" fontSize="12px">
            {channelNames}
          </Typography>
        );
      },
    },
    {
      field: "broadcastTypeIds",
      headerName: "Broadcast Types",
      width: 200,
      renderCell: (params: GridRenderCellParams<ChangesSubscription>) => {
        const ids = params.value || [];
        let broadcastNames = ids.join(", ");
        if (broadcastsQuery.data) {
          broadcastNames = ids
            .map((id: number) => {
              const broadcast = broadcastsQuery.data.find(
                (b) => b.broadcastId === id
              );
              return broadcast?.broadcastName || `${id}`;
            })
            .join(", ");
        }

        return (
          <Typography overflow="hidden" textOverflow="ellipsis" fontSize="12px">
            {broadcastNames}
          </Typography>
        );
      },
    },
    {
      field: "typeOfChangeIds",
      headerName: "Type of Changes",
      width: 200,
      renderCell: (params: GridRenderCellParams<ChangesSubscription>) => {
        const ids = params.value || [];
        return (
          <Typography overflow="hidden" textOverflow="ellipsis" fontSize="12px">
            {ids
              .map((id: number) => {
                const typeOfChange = TYPES_OF_CHANGE.find((t) => t.id === id);
                return typeOfChange?.name || `${id}`;
              })
              .join(", ")}
          </Typography>
        );
      },
    },
    {
      field: "delete",
      headerName: "",
      width: 50,
      sortable: false,
      filterable: false,
      renderCell: (params: GridRenderCellParams<ChangesSubscription>) => (
        <IconButton
          color="error"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (params.row.id) {
              onDelete(params.row.id);
            }
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={data}
        columns={columns}
        onCellClick={(params) => onSelect(params.row as ChangesSubscription)}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
      />
    </div>
  );
};

export default ChangesSubscriptionsTable;
