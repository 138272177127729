import { UIBroadcastType } from "../types/UIBroadcastType";

// more types might be added with time
enum BROADCAST_TYPES {
  LIVE = 1,
  HIGHLIGHTS = 2,
  LIVE_OR_SLIGHTLY_DELAYED = 3,
  DELAYED = 4,
  REPLAY = 5,
  FIRST_BROADCAST = 6,
  RELIVE = 7,
  REPLAY_ASTERISK = 8,
  LIVE_SHOW = 9,
  MAGAZINE = 11,
}

type BroadcastTypesMapping = {
  [key in BROADCAST_TYPES]: UIBroadcastType;
};

const MAPPING: BroadcastTypesMapping = {
  [BROADCAST_TYPES.LIVE]: UIBroadcastType.LIVE,
  [BROADCAST_TYPES.HIGHLIGHTS]: UIBroadcastType.LIVE,
  [BROADCAST_TYPES.LIVE_OR_SLIGHTLY_DELAYED]: UIBroadcastType.PROGRAM,
  [BROADCAST_TYPES.DELAYED]: UIBroadcastType.PROGRAM,
  [BROADCAST_TYPES.REPLAY]: UIBroadcastType.REPLAY,
  [BROADCAST_TYPES.FIRST_BROADCAST]: UIBroadcastType.PROGRAM,
  [BROADCAST_TYPES.RELIVE]: UIBroadcastType.RELIVE,
  [BROADCAST_TYPES.REPLAY_ASTERISK]: UIBroadcastType.REPLAY,
  [BROADCAST_TYPES.LIVE_SHOW]: UIBroadcastType.LIVE,
  [BROADCAST_TYPES.MAGAZINE]: UIBroadcastType.LIVE,
};

const getBroadcastUIType = (broadcastId: number): UIBroadcastType => {
  if (Object.values(BROADCAST_TYPES).includes(broadcastId)) {
    return MAPPING[broadcastId as BROADCAST_TYPES];
  }

  return UIBroadcastType.UNKNOWN;
};

export default getBroadcastUIType;
