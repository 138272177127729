import { Box, SxProps, Typography } from "@mui/material";

const NumberTag = ({ value, sx }: { value: number; sx?: SxProps }) => (
  <Box
    sx={{
      backgroundColor: "primary.main",
      color: "primary.contrastText",
      minWidth: "18px",
      height: "18px",
      borderRadius: "10px",
      textAlign: "center",
      paddingRight: "3px",
      paddingLeft: "3px",
      ...sx,
    }}
  >
    <Typography fontSize="12px" fontWeight="700">
      {value}
    </Typography>
  </Box>
);

export default NumberTag;
