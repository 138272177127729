import useTundraStore from "../../store/useTundraStore";
import LanguagePicker from "./LanguagePicker";

const ConnectedLanguagePicker = () => {
  const appliedUiChannels = useTundraStore((state) => state.uiChannels);
  const setAppliedUIChannels = useTundraStore((state) => state.setUIChannels);

  return (
    <LanguagePicker value={appliedUiChannels} onChange={setAppliedUIChannels} />
  );
};

export default ConnectedLanguagePicker;
