import { SVGProps } from "react";

const FilmIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        d="M6.002 0h12v2.995h3V0A3 3 0 0 1 24 3v18a3 3 0 0 1-2.998 3v-3.005h-3V24h-12v-3.005h-3V24H3a3 3 0 0 1-3-3V3a3 3 0 0 1 3-3h.002v2.995h3V0ZM9 16.5l6.75-4.5L9 7.5v9Zm12.002 1.495v-4.5h-3v4.5h3Zm0-7.5v-4.5h-3v4.5h3Zm-15 7.5v-4.5h-3v4.5h3Zm0-7.5v-4.5h-3v4.5h3Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default FilmIcon;
