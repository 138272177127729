import React, { useCallback } from "react";
import useTundraStore from "../../store/useTundraStore";
import { Button, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import getDefaultZoomForWindowHeight from "../../utils/getDefaultZoomForWindowHeight";

const ZoomLevel = ({
  minZoom,
  maxZoom,
}: {
  minZoom?: number;
  maxZoom?: number;
}) => {
  const zoomLevel = useTundraStore((state) => state.zoomLevel);
  const setZoomLevel = useTundraStore((state) => state.setZoomLevel);

  const [zoomedAlready, setZoomedAlready] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const relativeZoomLevel = () =>
    Math.round((zoomLevel / getDefaultZoomForWindowHeight()) * 100);

  const zoomIn = (maxZoom = 500) => {
    const newZoomLevel =
      ((relativeZoomLevel() + 10) / 100) * getDefaultZoomForWindowHeight();

    setZoomLevel(Math.min(maxZoom, newZoomLevel));

    setZoomedAlready(true);
  };

  const zoomOut = (minZoom = 50) => {
    const newZoomLevel =
      ((relativeZoomLevel() - 10) / 100) * getDefaultZoomForWindowHeight();

    setZoomLevel(Math.max(minZoom, newZoomLevel));
    setZoomedAlready(true);
  };

  const fitToScreen = useCallback(() => {
    setZoomLevel(Math.max(40, getDefaultZoomForWindowHeight()));
  }, [setZoomLevel]);

  const toggleFullScreen = async () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  };

  React.useEffect(() => {
    function onFullscreenChange() {
      setTimeout(() => fitToScreen(), 200);
    }

    function onResize() {
      if (!zoomedAlready) {
        fitToScreen();
      }
    }

    document.addEventListener("fullscreenchange", onFullscreenChange);
    window.addEventListener("resize", onResize);

    return () => {
      document.removeEventListener("fullscreenchange", onFullscreenChange);
      window.removeEventListener("resize", onResize);
    };
  }, [zoomedAlready, fitToScreen]);

  return (
    <>
      <Button
        id="zoom-level"
        onClick={handleClick}
        variant="text"
        sx={(theme) => ({
          fontSize: "15px",
          marginRight: "-2px",
          opacity: 0.99,
          lineHeight: "28px",
          background: theme.palette.background.default,
          paddingBottom: "2px",
          borderRadius: "0",
          "&:hover": {
            background: theme.palette.background.default,
          },
        })}
      >
        {relativeZoomLevel()}%
        <KeyboardArrowDownIcon />
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => zoomIn(maxZoom)}>Zoom in</MenuItem>
        <MenuItem onClick={() => zoomOut(minZoom)}>Zoom out</MenuItem>
        <MenuItem onClick={fitToScreen}>Fit to screen</MenuItem>
        <MenuItem onClick={toggleFullScreen}>Full screen</MenuItem>
      </Menu>
    </>
  );
};

export default ZoomLevel;
