import { CircularProgress } from "@mui/material";
import useTundraStore from "../../../store/useTundraStore";
import { LoadingOrErrorContainer } from "../../../components/EmissionsCalendar/EmissionsCalendar";
import { memo, useEffect } from "react";
import { useEmissions } from "../../../hooks/useEmissions";
import useChannels from "../../../hooks/useChannels";
import { EmissionsByChannel } from "./EmissionsByChannel";
import { EmissionsByDate } from "./EmissionsByDate";

const Emissions = () => {
  const pickerStartDate = useTundraStore((state) => state.pickerStartDate);
  const pickerEndDate = useTundraStore((state) => state.pickerEndDate);
  const organizeBy = useTundraStore((state) => state.organizeBy);
  const startOfDay = useTundraStore((state) => state.startOfDay);
  const titleId = useTundraStore((state) => state.titleId);
  const showAffiliates = useTundraStore((state) => state.showAffiliates);
  const uiChannels = useTundraStore((state) => state.uiChannels);
  const updateBaseWidth = useTundraStore((state) => state.updateBaseWidth);

  useEffect(() => {
    function onWindowResize() {
      setTimeout(() => updateBaseWidth(), 50);
    }

    window.addEventListener("resize", onWindowResize);

    return () => window.removeEventListener("resize", onWindowResize);
  });

  const emissionsQuery = useEmissions(
    showAffiliates,
    pickerStartDate,
    pickerEndDate,
    uiChannels,
    titleId,
    startOfDay
  );

  const channelsQuery = useChannels();

  if (emissionsQuery.isLoading || channelsQuery.isLoading) {
    return (
      <LoadingOrErrorContainer>
        <CircularProgress />
      </LoadingOrErrorContainer>
    );
  }

  if (
    emissionsQuery.error ||
    !emissionsQuery.data ||
    channelsQuery.error ||
    !channelsQuery.data
  )
    return (
      <LoadingOrErrorContainer>
        {"An error has occurred"}
      </LoadingOrErrorContainer>
    );

  if (organizeBy === "byDate")
    return (
      <EmissionsByDate
        programs={emissionsQuery.data}
        channels={channelsQuery.data}
      />
    );

  return (
    <EmissionsByChannel
      programs={emissionsQuery.data}
      channels={channelsQuery.data}
    />
  );
};

export default memo(Emissions);
