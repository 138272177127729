import { Avatar, Button, Menu, MenuItem, Typography } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import React, { useEffect, useState } from "react";
import { CustomUserClaims, UserClaims } from "@okta/okta-auth-js";
import useTundraStore from "../../store/useTundraStore";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LogoutOutlined } from "@mui/icons-material";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

const LogIn = () => {
  const auth = useOktaAuth();
  const logIn = () => auth.oktaAuth.signInWithRedirect();
  const logOut = () => auth.oktaAuth.signOut();
  const handleRedirect = () => {
    window.location.href = 'https://wbd.service-now.com/serviceportal?id=sc_cat_item&sys_id=2f28f5821b6cd250f27a43b1b24bcb42'; // URL de redirection
  };

  const [user, setUser] = useState<UserClaims<CustomUserClaims> | undefined>();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const setAuth = useTundraStore((state) => state.setAuth);

  useEffect(() => {
    if (!auth.authState?.isAuthenticated) return;
    auth.oktaAuth.getUser().then((u) => {
      console.log("user", u);
      setUser(u);
      setAuth(
        u.preferred_username || u.email || "",
        auth.authState?.accessToken?.accessToken || ""
      );
    });
  }, [
    auth.authState?.isAuthenticated,
    auth.authState?.accessToken?.accessToken,
    auth.oktaAuth,
    setAuth,
  ]);

  if (auth.authState && auth.authState.isAuthenticated) {
    return (
      <>
        <Button
          variant="text"
          onClick={(e) => {
            setMenuOpen((isOpen) => !isOpen);
            setAnchorEl(e.currentTarget);
          }}
        >
          {user && (
            <Avatar>
              {(user?.given_name || "")[0] + (user?.family_name || "")[0]}
            </Avatar>
          )}
          {user && (
            <>
              <Typography
                color="common.white"
                textTransform="none"
                fontSize="16px"
              >
                {user.given_name} {user.family_name?.split(".")[0]}
              </Typography>
              <KeyboardArrowDownIcon sx={{ color: "common.white" }} />
            </>
          )}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={isMenuOpen}
          onClose={() => setMenuOpen(false)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          sx={{
            "& .MuiList-root": {
              padding: 0,
              minWidth: "170px",
            },
          }}
        >
          <MenuItem onClick={handleRedirect} sx={{ height: "40px" }}>
          <SupportAgentIcon sx={{ marginRight: "16px", color: "text.primary" }} />
            <Typography fontSize="16px" color="text.primary">
              Request Support
            </Typography>
          </MenuItem>
          <MenuItem onClick={logOut} sx={{ height: "40px" }}>
            <LogoutOutlined
              sx={{ marginRight: "16px", color: "text.primary" }}
            />
            <Typography fontSize="16px" color="text.primary">
              Log Out
            </Typography>
          </MenuItem>

        </Menu>
      </>
    );
  }

  return (
    <Button
      variant="text"
      onClick={logIn}
      sx={{
        color: "common.white",
        textTransform: "none",
        fontSize: "16px",
        fontWeight: "400",
      }}
    >
      Log In
    </Button>
  );
};

export default LogIn;
