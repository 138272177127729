import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import useTitles from "../../hooks/useTitles";

const TitleTypePicker = ({
  value,
  onChange,
}: {
  value: number;
  onChange: (titleId: number) => void;
}) => {
  const titlesQuery = useTitles();

  if (titlesQuery.isLoading) return <>Loading...</>;

  if (titlesQuery.error || !titlesQuery.data) {
    return <>Error loading titles</>;
  }

  return (
    <FormControl size="small" sx={{ minWidth: "120px" }}>
      <InputLabel
        id="title-type-label"
        sx={{ backgroundColor: "background.default", marginTop: "1px" }}
      >
        Title Type
      </InputLabel>
      <Select
        id="titles-select"
        value={value}
        onChange={(event) => onChange(event.target.value as number)}
        labelId="title-type-label"
        variant="outlined"
        autoWidth
        sx={{
          minWidth: "110px",
          height: "40px",
          textTransform: "none",
          borderColor: "gray.200",
          fontSize: "16px",
          fontWeight: "400",
          color: "text.primary",
        }}
      >
        {titlesQuery.data.map((title) => (
          <MenuItem key={title.steId} value={title.steId}>
            {title.steName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TitleTypePicker;
