import useTundraStore from "../../store/useTundraStore";
import AffiliatePicker from "./AffiliatePicker";

const ConnectedAffiliatePicker = () => {
  const uiChannels = useTundraStore((state) => state.uiChannels);
  const setUIChannels = useTundraStore((state) => state.setUIChannels);

  return <AffiliatePicker value={uiChannels} onChange={setUIChannels} />;
};

export default ConnectedAffiliatePicker;
