import { Affiliate } from "../types/Affiliate";
import { Channel } from "../types/Channel";

const affiliatesToChannels = (affiliates: Affiliate[]): Channel[] => {
  let currentId = 0;
  const result: Channel[] = [];

  for (let affiliate of affiliates) {
    const channel: Channel = {
      id: currentId,
      name: affiliate.name,
      affiliateId: affiliate.id,
      slotId: 0,
      channelTypeId: 1,
      children: [],
    };

    for (let slot of affiliate.slots) {
      currentId++;
      channel.children.push({
        id: currentId,
        name: `${affiliate.name} — ${slot}`,
        affiliateId: affiliate.id,
        slotId: slot,
        channelTypeId: 1,
        children: [],
      });
    }

    result.push(channel);
    currentId++;
  }

  return result;
};

export default affiliatesToChannels;
