import useTundraStore from "../../store/useTundraStore";
import ByDayByChannelToggle from "./ByDayByChannelToggle";

const ConnectedByDayByChannelToggle = () => {
  const organizeBy = useTundraStore((state) => state.organizeBy);
  const setOrganizeBy = useTundraStore((state) => state.setOrganizeBy);

  return <ByDayByChannelToggle value={organizeBy} onChange={setOrganizeBy} />;
};

export default ConnectedByDayByChannelToggle;
