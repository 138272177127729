import ZoomLevel from "../../components/ZoomLevel/ZoomLevel";
import { Box, IconButton } from "@mui/material";
import useTundraStore, { DEFAULT_ZOOM } from "../../store/useTundraStore";
import ProgramDetailsModal from "./ProgramDetailsModal";
import TundraAppBar from "../../components/TundraAppBar/TundraAppBar";
import { useEffect, useState } from "react";
import Toolbar from "../../components/Toolbar/Toolbar";
import ConnectedLanguagePicker from "../../components/LanguagePicker/ConnectedLanguagePicker";
import FiltersModal from "./ScheduleFiltersModal/ScheduleFiltersModal";
import ConnectedAffiliatePicker from "../../components/AffiliatePicker/ConnectedAffiliatePicker";
import ConnectedAffiliatesToggle from "../../components/AffiliatesToggle/ConnectedAffiliatesToggle";
import ConnectedByDayByChannelToggle from "../../components/ByDayByChannelToggle/ConnectedByDayByChannelToggle";
import ConnectedTitleTypePicker from "../../components/TitleTypePicker/ConnectedTitleTypePicker";
import Container from "../../components/Container/Container";
import { TuneOutlined } from "@mui/icons-material";
import ConnectedRegularChannelsPicker from "../../components/RegularChannelsPicker/ConnectedRegularChannelsPicker";
import SearchInput from "../../components/SearchInput/SearchInput";
import { SEARCH_INPUT_OPTIONS } from "./constants";
import ConnectedDateRangePicker from "../../components/DateRangePicker/ConnectedDateRangePicker";
import ScheduleHelp from "./ScheduleHelp";
import Emissions from "./Emissions/Emissions";

const Schedule = () => {
  const showAffiliates = useTundraStore((state) => state.showAffiliates);
  const setSearchType = useTundraStore((state) => state.setSearchType);
  const setSearchString = useTundraStore((state) => state.setSearchString);
  const setZoomLevel = useTundraStore((state) => state.setZoomLevel);
  const searchType = useTundraStore((state) => state.searchType);
  const searchString = useTundraStore((state) => state.searchString);

  const [showFilters, setShowFilters] = useState(false);

  useEffect(() => {
    setZoomLevel(DEFAULT_ZOOM);
    setSearchType("all");
    setSearchString("");
  }, []);

  return (
    <>
      <TundraAppBar pageName="Schedule" innerComponent={<ScheduleHelp />} />
      <Container>
        <Toolbar
          actionComponent={
            <IconButton
              sx={{ marginRight: "16px", marginLeft: "8px" }}
              onClick={() => setShowFilters(true)}
            >
              <TuneOutlined sx={{ fontSize: "24px", color: "primary.main" }} />
            </IconButton>
          }
        >
          <SearchInput
            searchType={searchType}
            onTypeChange={setSearchType}
            value={searchString}
            onChange={setSearchString}
            options={SEARCH_INPUT_OPTIONS}
          />
          <ConnectedDateRangePicker />
          <ConnectedAffiliatesToggle />
          {!showAffiliates && <ConnectedRegularChannelsPicker />}
          {showAffiliates && <ConnectedAffiliatePicker />}
          <ConnectedByDayByChannelToggle />
          <ConnectedLanguagePicker />
          <ConnectedTitleTypePicker />
        </Toolbar>
        <Emissions />
        <ProgramDetailsModal />
        {showFilters && <FiltersModal onClose={() => setShowFilters(false)} />}
      </Container>
      <Box sx={{ position: "fixed", right: 0, top: "120px" }}>
        <ZoomLevel />
      </Box>
    </>
  );
};

export default Schedule;
