import { DateRangePicker as EurosportDateRangePicker } from "@shared/eurosport-ui";
import { Box } from "@mui/material";

const DateRangePicker = ({
  pickerStartDate,
  pickerEndDate,
  setPickerDates,
}: {
  pickerStartDate: string;
  pickerEndDate: string;
  setPickerDates: (startDate: string, endDate: string) => void;
}) => {
  const handleChange = (start: string | null, end: string | null) => {
    if (!start || !end) {
      return;
    }

    setPickerDates(start, end);
  };

  return (
    <Box>
      <EurosportDateRangePicker
        start={pickerStartDate}
        end={pickerEndDate}
        handleChange={handleChange}
      />
    </Box>
  );
};

export default DateRangePicker;
