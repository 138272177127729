import { useEffect, useRef, useState } from "react";
import { formatInTimeZone } from "date-fns-tz";
import { Box, Typography } from "@mui/material";
import EventIcon from "../EventIcon/EventIcon";
import { styled } from "@mui/material/styles";
import DecrocheIcon from "../EventIcon/DecrocheIcon";
import { GridProgram } from "../../types/GridProgram";
import { getGridColors } from "../../utils/getGridColors";

const EventAccent = styled("div")<{
  accentColor: string;
}>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background: ${({ accentColor }) => accentColor};
`;

const getDisplayableTimeRange = (program: GridProgram) => {
  return (
    formatInTimeZone(program.displayStartDate, "UTC", "HH:mm") +
    "—" +
    formatInTimeZone(program.displayEndDate, "UTC", "HH:mm")
  );
};

type EventProps = {
  program: GridProgram;
  height: number;
  top: number;
  deactivated?: boolean;
  gridDisplay: number[];
};

export const GridEvent = ({
  program,
  height,
  top,
  deactivated,
  gridDisplay,
}: EventProps) => {
  const [expanded, setExpanded] = useState(false);
  const expandedRef = useRef<HTMLDivElement>(null);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (expandedRef.current) {
        const el = expandedRef.current;
        if (
          el.parentElement &&
          el.offsetHeight + el.offsetTop > el.parentElement.clientHeight
        ) {
          setOffset(
            -(el.offsetHeight + el.offsetTop - el.parentElement.clientHeight)
          );
        }
      }
    }, 10);
  }, [expanded, top, height]);

  const { backgroundColor, color, isBold } = getGridColors(program);

  const isDecroche = program.isDecro;

  const timeRange = (
    <Typography component="div" fontSize={13}>
      {getDisplayableTimeRange(program)}
    </Typography>
  );

  const shouldShowBreaks = gridDisplay.includes(1);
  const shouldShowFeeds = gridDisplay.includes(2);

  const eventContent = (
    <>
      <Box
        width="100%"
        overflow="hidden"
        paddingLeft="8px"
        flexBasis="200px"
        borderRight={`1px solid white`}
      >
        <Box
          sx={{
            float: "right",
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          {isDecroche && (height > 48 || expanded) && (
            <EventIcon background="#000000" Icon={DecrocheIcon} />
          )}
        </Box>
        {(height > 50 || expanded) && timeRange}
        <Typography fontWeight={isBold ? "700" : "400"}>
          {program.title}
        </Typography>
        {program.comment && (
          <>
            <Typography
              fontSize="8px"
              fontWeight="700"
              marginTop="8px"
              display="block"
            >
              Comment
            </Typography>
            <Typography fontSize="11px">{program.comment}</Typography>
          </>
        )}
        {program.guide &&
          program.guide.texts.map((text, i) => (
            <>
              <Typography
                fontSize="8px"
                fontWeight="700"
                marginTop="8px"
                display="block"
                marginBottom="-5px"
              >
                {program.guide?.titles[i] || ""}
              </Typography>
              <Box
                fontSize="11px"
                dangerouslySetInnerHTML={{ __html: text }}
              ></Box>
            </>
          ))}
        {program.spare && (
          <>
            <Typography
              fontSize="8px"
              fontWeight="700"
              marginTop="8px"
              display="block"
            >
              Guide
            </Typography>
            <Typography fontSize="11px">
              {program.spare.brD_ID} {program.spare.title}{" "}
              {program.spare.startDateTimeStr}
              {program.spare.title}
            </Typography>
          </>
        )}
        {expanded &&
          (program.firstBroadcastChannelTwoChar ||
            program.firstBroadcastDate) && (
            <>
              <Typography
                fontSize="8px"
                fontWeight="700"
                marginTop="8px"
                display="block"
              >
                First Broadcast
              </Typography>
              <Typography fontSize="11px">
                {program.firstBroadcastChannelTwoChar}{" "}
                {program.firstBroadcastDate}
              </Typography>
            </>
          )}
        <EventAccent accentColor={color} />
      </Box>
      {shouldShowBreaks && (
        <Box
          fontSize="10px"
          paddingLeft="6px"
          paddingTop="6px"
          flexBasis="80px"
          paddingBottom="6px"
        >
          {program.breaks.map((b) => (
            <Typography fontSize="11px">{b}</Typography>
          ))}
        </Box>
      )}
      {shouldShowFeeds && (
        <Box
          fontSize="10px"
          paddingLeft="6px"
          flexBasis="80px"
          borderLeft={`1px solid white`}
        >
          {program.feed}
        </Box>
      )}
    </>
  );

  return (
    <>
      <Box
        onClick={() => console.log(program)}
        onMouseEnter={() => {
          !deactivated && setExpanded(true);
        }}
        onMouseLeave={() => {
          !deactivated && setExpanded(false);
        }}
        sx={{
          borderRadius: "4px",
          overflow: "hidden",
          background: backgroundColor,
          color: color,
          position: "absolute",
          left: `${1 + (program.conflictingLevel || 0) * 10}px`,
          right: "1px",
          zIndex: 10,
          height: `${height}px`,
          top: `${top}px`,
          padding: 0,
          opacity: deactivated ? "0.2" : null,
          cursor: deactivated ? "default" : "pointer",
          pointerEvents: deactivated ? "none" : null,
          display: "flex",
          borderBottom: "1px solid #ffffff",
        }}
      >
        {eventContent}
      </Box>
      {expanded && (
        <Box
          ref={expandedRef}
          sx={{
            borderRadius: "4px",
            overflow: "hidden",
            background: backgroundColor,
            color: color,
            padding: 0,
            position: "absolute",
            left: `${1 + (program.conflictingLevel || 0) * 10}px`,
            right: "1px",
            height: "auto !important",
            pointerEvents: "none",
            zIndex: 11,
            minHeight: height,
            top: `${top + offset}px`,
            display: "flex",
            borderBottom: "1px solid #ffffff",
          }}
        >
          {eventContent}
        </Box>
      )}
    </>
  );
};
