import { CircularProgress } from "@mui/material";
import useCountries from "../../hooks/useCountries";
import { MultiSelectPicker } from "@shared/eurosport-ui";
import { Language } from "@mui/icons-material";

const CountriesPicker = ({
  value,
  onChange,
}: {
  value: number[];
  onChange: (countries: number[]) => void;
}) => {
  const countriesQuery = useCountries();

  if (countriesQuery.isLoading) {
    return <CircularProgress />;
  }

  if (countriesQuery.isError || !countriesQuery.data) {
    return <>Error loading countries</>;
  }

  return (
    <MultiSelectPicker
      label={"Countries"}
      value={value}
      onChange={onChange}
      options={countriesQuery.data}
      getOptionLabel={({ countryName }) => countryName}
      getOptionId={({ countryId }) => countryId}
      icon={<Language />}
    />
  );
};

export default CountriesPicker;
