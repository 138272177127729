import useChannels from "../../hooks/useChannels";
import ChannelPicker from "../ChannelPicker/ChannelPicker";
import { CircularProgress } from "@mui/material";
import getChannelDefaultLanguage from "../../utils/getChannelDefaultLanguage";
import { ChannelType } from "../../types/ChannelType";
import { UIChannel } from "../../types/UIChannel";

export const REGULAR_CHANNELS_TABS = [
  {
    label: "Linear",
    typeId: 1,
    searchLabel: "Search linear channels...",
    allowSelectingOnTopLevel: true,
  },
  {
    label: "Digital",
    typeId: 2,
    searchLabel: "Search digital channels...",
    allowSelectingOnTopLevel: false,
  },
];

const RegularChannelsPicker = ({
  value,
  onChange,
}: {
  value: UIChannel[];
  onChange: (uiChannels: UIChannel[]) => void;
}) => {
  const channelsQuery = useChannels();

  if (channelsQuery.isLoading) return <CircularProgress />;

  if (!channelsQuery.data) return <>Error loading Channels</>;

  return (
    <ChannelPicker
      channels={channelsQuery.data.channels}
      channelsSelected={value
        .filter((channel) => channel.type === ChannelType.Channel)
        .map(({ id }) => id)}
      setSelected={(ids: number[]) => {
        onChange(
          ids.map((id) => {
            return {
              type: ChannelType.Channel,
              id: id,
              languageId:
                getChannelDefaultLanguage(id, channelsQuery.data.channels) || 0,
            };
          })
        );
      }}
      label="Channels"
      tabs={REGULAR_CHANNELS_TABS}
    />
  );
};

export default RegularChannelsPicker;
