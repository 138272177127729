import useTundraStore from "../../store/useTundraStore";
import SearchInput from "../../components/SearchInput/SearchInput";

export const GridSearch = () => {
  const searchType = useTundraStore((state) => state.searchType);
  const searchString = useTundraStore((state) => state.searchString);
  const setSearchType = useTundraStore((state) => state.setSearchType);
  const setSearchString = useTundraStore((state) => state.setSearchString);
  return (
    <SearchInput
      searchType={searchType}
      onTypeChange={setSearchType}
      value={searchString}
      onChange={setSearchString}
      options={[
        { id: "all", label: "All" },
        { id: "title", label: "Title" },
        { id: "comment", label: "Comment" },
        { id: "firstBroadcast", label: "First Broadcast Channel" },
      ]}
    />
  );
};
