import { MultiSelectPicker } from "@shared/eurosport-ui";
import { Dvr } from "@mui/icons-material";
import useTundraStore from "../../store/useTundraStore";

export const GRID_DISPLAY_OPTIONS = [
  { name: "Breaks", id: 1 },
  { name: "Feeds", id: 2 },
];

const GridDisplayPicker = () => {
  const gridDisplay = useTundraStore((state) => state.gridDisplay);
  const setGridDisplay = useTundraStore((state) => state.setGridDisplay);

  return (
    <MultiSelectPicker
      label="Display"
      value={gridDisplay}
      icon={<Dvr />}
      onChange={setGridDisplay}
      options={GRID_DISPLAY_OPTIONS}
      getOptionLabel={({ name }) => name}
      getOptionId={({ id }) => id}
      sx={{ whiteSpace: "nowrap", minWidth: "120px" }}
    />
  );
};

export default GridDisplayPicker;
