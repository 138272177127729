import { styled } from "@mui/material/styles";
import MuiTooltip, {
  TooltipProps,
  tooltipClasses,
} from "@mui/material/Tooltip";

const Tooltip = styled(({ className, ...props }: TooltipProps) => (
  <MuiTooltip
    arrow
    placement="top"
    classes={{ popper: className }}
    sx={{ boxShadow: 0 }}
    {...props}
  />
))(({ theme }) => ({
  filter: `drop-shadow(${theme.shadows[1]})`,
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.default,
    color:
      theme.palette.mode === "dark"
        ? theme.palette.text.primary
        : "rgba(0, 0, 0, 0.87)",
    boxShadow: 0,
    fontSize: 14,
    filter: "none",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background.default,
  },
}));

export default Tooltip;
