import { Channel } from "../../types/Channel";

export const getChannelsCount = (
  channels: Channel[],
  selectedChannels: number[],
  typeId: number
) => {
  return channels?.reduce((count: number, chan) => {
    if (chan.channelTypeId === typeId) {
      if (selectedChannels.includes(chan.id)) {
        count = count + 1;
      }
      chan.children?.forEach((c) => {
        if (selectedChannels.includes(c.id)) {
          count = count + 1;
        }
      });
    }
    return count;
  }, 0);
};
