import { VideocamOutlined } from "@mui/icons-material";

const MulticamIcon = () => (
  <VideocamOutlined
    sx={{
      color: "white",
      scale: "0.7 !important",
      marginTop: "-1px",
      marginLeft: "-1px",
    }}
  />
);

export default MulticamIcon;
