import { useMutation } from "@tanstack/react-query";
import useTundraStore from "../store/useTundraStore";

const GET_FILTERS_API = process.env.REACT_APP_GET_FILTERS_API as string;

const useDeleteFilter = () => {
  const userEmail = useTundraStore((state) => state.userEmail);
  const authToken = useTundraStore((state) => state.authToken);

  return useMutation((id: number) => {
    const params = new URLSearchParams();
    params.set("email", userEmail || "");
    params.set("token", authToken || "");
    params.set("id", `${id}`);
    return fetch(`${GET_FILTERS_API}?${params.toString()}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return res.json();
    });
  });
};

export default useDeleteFilter;
