const constructParametersForChangesRequest = (
  startDate: string,
  endDate: string,
  channelIds: number[],
  typesOfChanges: number[],
  broadcastTypeIds: number[]
) => ({
  broadcastingTypeIds: broadcastTypeIds,
  typeOfChanges: typesOfChanges,
  broadcastId: "",
  valEmiId: "",
  programId: "",
  spevId: "",
  startDate: `${startDate}T00:00:00.000Z`,
  endDate: `${endDate}T00:00:00.000Z`,
  channelIds,
});

export default constructParametersForChangesRequest;
