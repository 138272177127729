import { CircularProgress } from "@mui/material";
import useBroadcasts from "../../hooks/useBroadcasts";
import { Podcasts } from "@mui/icons-material";
import { MultiSelectPicker } from "@shared/eurosport-ui";

const BroadcastTypesPicker = ({
  value,
  onChange,
}: {
  value: number[];
  onChange: (value: number[]) => void;
}) => {
  const broadcastsQuery = useBroadcasts();

  if (broadcastsQuery.isLoading) {
    return <CircularProgress size="small" />;
  }

  if (broadcastsQuery.isError || !broadcastsQuery.data) {
    return <>"Error loading broadcast types"</>;
  }

  return (
    <MultiSelectPicker
      sx={{ minWidth: "210px" }}
      label={"Broadcast Type"}
      value={value}
      onChange={onChange}
      options={broadcastsQuery.data}
      getOptionLabel={({ broadcastName }) => broadcastName}
      getOptionId={({ broadcastId }) => broadcastId}
      icon={<Podcasts />}
    />
  );
};

export default BroadcastTypesPicker;
