import {
  Box,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Search as IconSearch } from "@mui/icons-material";

const SearchInput = ({
  searchType,
  onTypeChange,
  value,
  onChange,
  options,
}: {
  searchType?: string;
  onTypeChange?: (type: string) => void;
  value: string;
  onChange: (value: string) => void;
  options?: { id: string; label: string }[];
}) => {
  const showTypeSelector = options && options.length > 0 && searchType;
  return (
    <Box sx={{ display: "flex", alignItems: "bottom" }}>
      {showTypeSelector && (
        <Select
          value={searchType}
          size="small"
          sx={(theme) => ({
            height: "40px",
            borderRadius: "4px 0 0 4px",
            border: "none",
            marginTop: "0px",
            marginRight: "-1px",
            background: theme.palette.primary.main,
            "& .MuiSelect-select": {
              background: theme.palette.primary.main,
              color: theme.palette.primary.contrastText,
            },
            "& .MuiSvgIcon-root": {
              color: theme.palette.primary.contrastText,
            },
          })}
          inputProps={{
            sx: {
              backgroundColor: "primary.main",
              color: "primary.contrastText",
            },
          }}
          onChange={(event) => onTypeChange && onTypeChange(event.target.value)}
        >
          {options.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}

      <TextField
        label={"Search a program..."}
        variant="outlined"
        value={value}
        size="small"
        InputProps={{
          sx: { height: "40px" },
          startAdornment: (
            <InputAdornment position="start">
              <IconSearch />
            </InputAdornment>
          ),
        }}
        sx={{
          minWidth: "150px",
          height: "40px",
          padding: 0,
          flexGrow: 1,
          "& .MuiInputBase-root": {
            borderRadius: showTypeSelector ? "0 4px 4px 0" : "4px",
          },
        }}
        onChange={(event) => onChange(event.target.value)}
      />
    </Box>
  );
};

export default SearchInput;
