import * as React from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import { SerializedFilter } from "../../../types/SerializedFilter";
import { IconButton } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FilterDeleteModal from "./FilterDeleteModal";
import { useState } from "react";

const RestoreFilters = ({
  savedFilters,
  onRestore,
  onDelete,
}: {
  savedFilters: { name: string; id: number; filter: SerializedFilter }[];
  onRestore: (filter: SerializedFilter) => void;
  onDelete: (filterId: number) => void;
}) => {
  const [open, setOpen] = useState(false);
  const [filterToDelete, setFilterToDelete] = useState<[number, string] | null>(
    null
  );
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Stack direction="row" spacing={2}>
        <IconButton ref={anchorRef} onClick={handleToggle}>
          <BookmarkBorderIcon sx={{ fontSize: "22px" }} />
        </IconButton>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
          sx={{ zIndex: 1000 }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                    {savedFilters.map(({ name, filter, id }) => (
                      <MenuItem
                        onClick={(e) => {
                          onRestore(filter);
                          handleClose(e);
                        }}
                        key={name}
                      >
                        {name}
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setFilterToDelete([id, name]);
                          }}
                        >
                          <DeleteOutlineIcon />
                        </IconButton>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Stack>
      {filterToDelete !== null && (
        <FilterDeleteModal
          isOpen={filterToDelete !== null}
          onClose={() => {
            setFilterToDelete(null);
          }}
          onSubmit={(name) => {
            onDelete(name);
            setFilterToDelete(null);
          }}
          filterToDelete={filterToDelete}
        />
      )}
    </>
  );
};

export default RestoreFilters;
