import { CircularProgress } from "@mui/material";
import useClassifiers, { ClassifierKind } from "../../hooks/useClassifiers";
import { MultiSelectPicker } from "@shared/eurosport-ui";

import {
  EmojiEvents,
  LocalActivity,
  SportsHandball,
} from "@mui/icons-material";

const LABELS = {
  [ClassifierKind.Sport]: "Sports",
  [ClassifierKind.Event]: "Events",
  [ClassifierKind.Competition]: "Competitions",
};

const ICONS = {
  [ClassifierKind.Sport]: <SportsHandball />,
  [ClassifierKind.Event]: <LocalActivity />,
  [ClassifierKind.Competition]: <EmojiEvents />,
};

const ClassificationPicker = ({
  kind,
  value,
  onChange,
}: {
  kind: ClassifierKind;
  value: number[];
  onChange: (v: number[]) => void;
}) => {
  const elementsQuery = useClassifiers(kind);
  const label = LABELS[kind];

  if (elementsQuery.isLoading) {
    return <CircularProgress size="small" />;
  }

  if (elementsQuery.isError || !elementsQuery.data) {
    return <>Error loading {label}</>;
  }

  return (
    <MultiSelectPicker
      label={label}
      value={value}
      onChange={onChange}
      options={elementsQuery.data}
      getOptionLabel={({ name }) => name}
      getOptionId={({ id }) => id}
      icon={ICONS[kind]}
    />
  );
};

export default ClassificationPicker;
