import React, { memo, useState } from "react";
import Button from "@mui/material/Button";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Tv from "@mui/icons-material/Tv";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import NumberTag from "../NumberTag/NumberTag";
import { Close, DeleteOutline } from "@mui/icons-material";
import ChannelsTree from "./ChannelsTree";
import { getChannelsCount } from "./getChannelsCount";
import { Channel } from "../../types/Channel";

type TabConfig = {
  typeId: number;
  label: string;
  searchLabel: string;
  allowSelectingOnTopLevel: boolean;
};

type ChannelPickerProps = {
  channels: Channel[];
  channelsSelected: number[];
  setSelected: (ids: number[]) => void;
  label?: string;
  tabs: TabConfig[];
};

const ChannelsDialog = ({
  channels,
  value,
  onChange,
  onClose,
  label,
  tabs,
}: {
  channels: Channel[];
  value: number[];
  onChange: (value: number[]) => void;
  onClose: () => void;
  label: string;
  tabs: TabConfig[];
}) => {
  const [tabSelected, setTabSelected] = useState(1);
  const [selectedChannels, setSelectedChannels] = useState(value);
  const currentTab = tabs.find((tab) => tab.typeId === tabSelected) || tabs[0];

  function handleTabChange(e: any, value: number) {
    e.stopPropagation();
    setTabSelected(value);
  }

  function handleRemoveAll() {
    let ids = selectedChannels.reduce((list: number[], id: number) => {
      let channel = channels.find(
        (chan: Channel) =>
          chan.id === id || chan.children?.find((c) => c.id === id)
      );
      if (channel && channel.channelTypeId !== currentTab.typeId) {
        list.push(id);
      }
      return list;
    }, []);
    setSelectedChannels(ids);
  }

  function handleAddAll() {
    let ids = channels.reduce((list: number[], chan) => {
      if (chan.channelTypeId === currentTab.typeId) {
        list.push(chan.id);
        chan?.children.forEach((c) => {
          list.push(c.id);
        });
      }
      return list;
    }, selectedChannels);
    setSelectedChannels(Array.from(new Set(ids)));
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullWidth
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "500px",
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {label}
        <IconButton onClick={onClose}>
          <Close sx={{ color: "primary.main", fontSize: "22px" }} />
        </IconButton>
      </DialogTitle>

      <DialogContent
        dividers
        sx={{ backgroundColor: "background.default", p: 0 }}
      >
        {tabs.length > 1 && (
          <Tabs
            value={tabSelected}
            onChange={handleTabChange}
            centered={true}
            variant="fullWidth"
          >
            {tabs.map((tab) => {
              const channelsCount = getChannelsCount(
                channels,
                selectedChannels,
                tab.typeId
              );

              return (
                <Tab
                  sx={{
                    fontSize: "15px",
                    textTransform: "none",
                    fontWeight: "700",
                  }}
                  label={
                    <Box sx={{ display: "flex", gap: "8px" }}>
                      {tab.label}{" "}
                      {!!channelsCount && <NumberTag value={channelsCount} />}
                    </Box>
                  }
                  value={tab.typeId}
                />
              );
            })}
          </Tabs>
        )}

        <ChannelsTree
          channels={channels}
          key={tabSelected}
          value={selectedChannels}
          onChange={setSelectedChannels}
          channelsType={tabSelected}
          searchLabel={currentTab.searchLabel}
          allowSelectingOnTopLevel={currentTab.allowSelectingOnTopLevel}
        />
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Stack spacing={2} direction="row" flexWrap="wrap">
          <Button
            onClick={handleRemoveAll}
            variant="text"
            startIcon={<DeleteOutline />}
            color="secondary"
          >
            Remove all
          </Button>
          <Button onClick={handleAddAll} variant="text">
            Add all
          </Button>
        </Stack>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              onChange(selectedChannels);
              onClose();
            }}
          >
            Apply
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

const ChannelPicker: React.FC<ChannelPickerProps> = ({
  channels,
  channelsSelected,
  setSelected,
  label,
  tabs,
}) => {
  const [showModal, setShowModal] = useState(false);
  label = label || "Channels";

  function handleShowModal() {
    setShowModal(!showModal);
  }

  return (
    <>
      <Button
        variant="filter"
        onClick={handleShowModal}
        startIcon={<Tv />}
        endIcon={
          channelsSelected.length > 0 && (
            <NumberTag value={channelsSelected.length} />
          )
        }
        sx={{ minWidth: "150px", backgroundColor: "background.default" }}
      >
        {label}
      </Button>
      {showModal && (
        <ChannelsDialog
          channels={channels}
          value={channelsSelected}
          onChange={setSelected}
          onClose={() => setShowModal(false)}
          label={label}
          tabs={tabs}
        />
      )}
    </>
  );
};

export default memo(ChannelPicker);
