import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { GRID_COLOR_RULES } from "../../utils/getGridColors";
import getBroadcastShortLabel from "../../utils/getBroadcastShortLabel";
import { Close, HelpOutline } from "@mui/icons-material";

const GridHelp = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={() => setIsModalOpen(true)}
        sx={{
          color: "white",
        }}
      >
        <HelpOutline />
      </IconButton>
      {isModalOpen && (
        <Dialog
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          maxWidth="md"
        >
          <DialogTitle
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Grid Help
            <IconButton onClick={onClose}>
              <Close sx={{ color: "primary.main", fontSize: "22px" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Typography fontSize="18px" marginBottom="12px">
              This is the visualisation of the rules that are applied to the
              grid. The rules are applied in the order they are listed below. In
              some cases, the text color is broadcast specific. This means that
              the background will be chosen based on the main rule, but the text
              color might change depending on broadcast type.
            </Typography>

            <Stack direction="column" spacing={2}>
              {GRID_COLOR_RULES.map((rule) => (
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                      backgroundColor: rule.background,
                      padding: "4px 8px",
                    }}
                  >
                    <Box
                      sx={{
                        color: rule.color,
                        fontWeight: rule.isBold ? "700" : "400",
                      }}
                    >
                      {rule.name}: {rule.description}
                      {rule.broadcastSpecific && (
                        <Stack direction="row" spacing={2}>
                          {rule.broadcastSpecific.map((broadcast) => (
                            <Typography
                              fontSize="14px"
                              color={broadcast.color}
                              fontWeight={rule.isBold ? "700" : "400"}
                            >
                              {getBroadcastShortLabel(
                                parseInt(broadcast.broadcastTypeId)
                              )}
                            </Typography>
                          ))}
                        </Stack>
                      )}
                    </Box>
                  </Box>
                </Box>
              ))}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default GridHelp;
