import useTundraStore from "../store/useTundraStore";
import { useQuery } from "@tanstack/react-query";
import { ChangesSubscription } from "../types/ChangesSubscription";

const CHANGES_GET_SUBSCRIPTIONS_API_URL = process.env
  .REACT_APP_CHANGES_GET_SUBSCRIPTIONS_API as string;

const getChangesSubscriptions = (
  userEmail?: string,
  authToken?: string
): Promise<ChangesSubscription[]> => {
  if (!userEmail || !authToken) {
    return new Promise(() => {});
  }

  const params = new URLSearchParams();
  params.set("email", userEmail || "");
  params.set("token", authToken || "");

  return fetch(CHANGES_GET_SUBSCRIPTIONS_API_URL + `?${params.toString()}`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return res.json();
    })
    .then((data) => {
      if (data.status === 403) {
        throw new Error("Auth error when loading subscriptions");
      }
      return data;
    })
    .then((subs) => {
      return subs || [];
    });
};

const useChangesSubscriptions = () => {
  const userEmail = useTundraStore((state) => state.userEmail);
  const authToken = useTundraStore((state) => state.authToken);

  return useQuery({
    queryKey: ["filtersData", userEmail, authToken],
    queryFn: () => getChangesSubscriptions(userEmail, authToken),
  });
};

export default useChangesSubscriptions;
