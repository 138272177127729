import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import {
  Alert,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { Close } from "@mui/icons-material";

const FilterNameModal = ({
  isOpen,
  onClose,
  onSubmit,
  selectedNames,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (name: string) => void;
  selectedNames: string[];
}) => {
  const [name, setName] = useState("");
  const [error, setError] = useState<string | null>();

  return (
    <>
      <Dialog onClose={onClose} open={isOpen}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Filters Name
          <IconButton onClick={onClose}>
            <Close sx={{ color: "primary.main", fontSize: "22px" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers
          sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
        >
          <TextField
            size={"small"}
            value={name}
            onChange={(e) => {
              const newName = e.target.value;

              if (newName.length === 0) {
                setError("Name can not be empty");
              } else {
                setError(null);
              }
              setName(e.target.value);
            }}
            fullWidth={true}
            placeholder={"Name your filter"}
          />
          {error && (
            <Alert severity="error" variant="filled">
              {error}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              onSubmit(name);
            }}
            disabled={error !== null || name.length === 0}
          >
            Save Filter
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FilterNameModal;
