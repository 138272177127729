import { create } from "zustand";
import { isMonday, previousMonday } from "date-fns";
import { StartOfDay } from "../types/StartOfDay";
import { OrganizeScheduleBy } from "../types/OrganizeScheduleBy";
import { ScheduleProgram } from "../types/ScheduleProgram";
import { UIChannel } from "../types/UIChannel";
import { ChannelType } from "../types/ChannelType";
import { SerializedFilter } from "../types/SerializedFilter";
import dayjs from "dayjs";
import getDefaultZoomForWindowHeight from "../utils/getDefaultZoomForWindowHeight";
import { serializeFilters } from "../utils/serializeFilters";
import { deserializeFilters } from "../utils/deserializeFilters";
import { PaletteMode } from "@mui/material";

export interface TundraFilters {
  pickerStartDate: string;
  pickerEndDate: string;
  organizeBy: OrganizeScheduleBy;
  startOfDay: StartOfDay;
  titleId: number;
  classifiersSports: number[];
  classifiersEvents: number[];
  classifiersCompetitions: number[];
  countries: number[];
  searchType: string;
  searchString: string;
  broadcastTypes: number[];
  showAffiliates: boolean;
  uiChannels: UIChannel[];
}

interface TundraState extends TundraFilters {
  colorMode: PaletteMode;
  setColorMode: (mode: PaletteMode) => void;
  getFilter: () => TundraFilters;
  zoomLevel: number;
  setZoomLevel: (to: number) => void;
  setPickerDates: (start?: string, end?: string) => void;
  setOrganizeBy: (by: OrganizeScheduleBy) => void;
  setStartOfDay: (at: StartOfDay) => void;
  selectedProgram?: ScheduleProgram;
  setSelectedProgram: (program?: ScheduleProgram) => void;
  setTitleId: (titleId: number) => void;
  setClassifiersSports: (values: number[]) => void;
  setClassifiersEvents: (values: number[]) => void;
  setClassifiersCompetitions: (values: number[]) => void;
  setCountries: (values: number[]) => void;
  setSearchType: (value: string) => void;
  setSearchString: (value: string) => void;
  setBroadcastTypes: (value: number[]) => void;
  setShowAffiliates: (value: boolean) => void;
  setUIChannels: (value: UIChannel[]) => void;
  applyFilter: (filter: TundraFilters) => void;
  applySerializedFilter: (filter: SerializedFilter) => void;
  resetFilter: () => void;
  isAuthenticated: boolean;
  userEmail?: string;
  authToken?: string;
  setAuth: (userEmail: string, authToken: string) => void;
  baseWidth: number;
  updateBaseWidth: () => void;
  gridBroadcastId: string;
  setGridBroadcastId: (v: string) => void;
  gridValEmiId: string;
  setGridValEmiId: (v: string) => void;
  gridProgramId: string;
  setGridProgramId: (v: string) => void;
  gridSPEVId: string;
  setGridSPEVId: (v: string) => void;
  gridDisplay: number[];
  setGridDisplay: (v: number[]) => void;
}

export const DEFAULT_ZOOM = Math.max(40, getDefaultZoomForWindowHeight());
const today = new Date();
const TODAY_DATE = new Date(
  Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate())
);

const DEFAULT_PICKER_START_DATE = dayjs(
  isMonday(TODAY_DATE) ? TODAY_DATE : previousMonday(TODAY_DATE)
).format("YYYY-MM-DD");

const DEFAULT_PICKER_END_DATE = dayjs(DEFAULT_PICKER_START_DATE)
  .add(6, "day")
  .format("YYYY-MM-DD");

export const DEFAULT_FILTERS: TundraFilters = {
  pickerStartDate: DEFAULT_PICKER_START_DATE,
  pickerEndDate: DEFAULT_PICKER_END_DATE,
  organizeBy: "byChannel",
  startOfDay: "midnight",
  titleId: 1,
  classifiersSports: [],
  classifiersEvents: [],
  classifiersCompetitions: [],
  countries: [],
  searchType: "all",
  searchString: "",
  broadcastTypes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 11],
  showAffiliates: false,
  uiChannels: [{ type: ChannelType.Channel, id: 0, languageId: 0 }],
};

const getBaseWidth = () =>
  Math.max(100, Math.min(400, Math.round((window.innerWidth - 90) / 7)));

const savedFilters = localStorage.getItem("tundraFilters");
const savedColorMode = localStorage.getItem("tundraColorMode");

const initialFilters: TundraFilters = savedFilters
  ? deserializeFilters(JSON.parse(savedFilters))
  : DEFAULT_FILTERS;

const useTundraStore = create<TundraState>()((set, getState) => ({
  colorMode: (savedColorMode as PaletteMode) || "light",
  setColorMode: (mode: PaletteMode) => set({ colorMode: mode }),
  getFilter: (): TundraFilters => ({
    pickerStartDate: getState().pickerStartDate,
    pickerEndDate: getState().pickerEndDate,
    organizeBy: getState().organizeBy,
    startOfDay: getState().startOfDay,
    titleId: getState().titleId,
    classifiersSports: getState().classifiersSports,
    classifiersEvents: getState().classifiersEvents,
    classifiersCompetitions: getState().classifiersCompetitions,
    countries: getState().countries,
    searchType: getState().searchType,
    searchString: getState().searchString,
    broadcastTypes: getState().broadcastTypes,
    showAffiliates: getState().showAffiliates,
    uiChannels: getState().uiChannels,
  }),
  zoomLevel: DEFAULT_ZOOM,
  setZoomLevel: (zoomLevel) => set({ zoomLevel }),
  setPickerDates: (start, end) =>
    set({
      pickerStartDate: start || DEFAULT_PICKER_START_DATE,
      pickerEndDate: end || DEFAULT_PICKER_END_DATE,
    }),
  setOrganizeBy: (organizeBy) => set({ organizeBy }),
  setStartOfDay: (startOfDay) => set({ startOfDay }),
  setSelectedProgram: (selectedProgram?: ScheduleProgram) =>
    set({ selectedProgram }),
  setTitleId: (titleId) => set({ titleId }),
  setClassifiersSports: (classifiersSports) => set({ classifiersSports }),
  setClassifiersEvents: (classifiersEvents) => set({ classifiersEvents }),
  setClassifiersCompetitions: (classifiersCompetitions) =>
    set({ classifiersCompetitions }),
  setCountries: (countries) => set({ countries }),
  setSearchType: (searchType) => set({ searchType }),
  setSearchString: (searchString) => set({ searchString }),
  setBroadcastTypes: (broadcastTypes) => set({ broadcastTypes }),
  setShowAffiliates: (showAffiliates) => {
    if (showAffiliates) {
      set({
        showAffiliates,
        uiChannels: [{ type: ChannelType.Affiliate, id: 13, slotId: 1 }],
      });
    } else {
      set({
        showAffiliates,
        uiChannels: [{ type: ChannelType.Channel, id: 0, languageId: 0 }],
      });
    }
  },
  setUIChannels: (uiChannels) =>
    set({
      uiChannels: uiChannels.sort(
        (channelA, channelB) => channelA.id - channelB.id
      ),
    }),
  applyFilter: (filter: TundraFilters) => set({ ...filter }),
  applySerializedFilter: (filter: SerializedFilter) =>
    set(deserializeFilters(filter)),
  resetFilter: () => set(DEFAULT_FILTERS),
  isAuthenticated: false,
  setAuth: (userEmail, authToken) =>
    set({ isAuthenticated: true, userEmail, authToken }),
  baseWidth: getBaseWidth(),
  updateBaseWidth: () => set({ baseWidth: getBaseWidth() }),
  gridBroadcastId: "",
  setGridBroadcastId: (gridBroadcastId) => set({ gridBroadcastId }),
  gridValEmiId: "",
  setGridValEmiId: (gridValEmiId) => set({ gridValEmiId }),
  gridProgramId: "",
  setGridProgramId: (gridProgramId) => set({ gridProgramId }),
  gridSPEVId: "",
  setGridSPEVId: (gridSPEVId) => set({ gridSPEVId }),
  gridDisplay: [],
  setGridDisplay: (gridDisplay) => set({ gridDisplay }),
  ...initialFilters,
}));

useTundraStore.subscribe((state) => {
  // Don't keep start date and end date
  let filters = state.getFilter();
  filters.pickerStartDate = DEFAULT_PICKER_START_DATE;
  filters.pickerEndDate = DEFAULT_PICKER_END_DATE;

  localStorage.setItem(
    "tundraFilters",
    JSON.stringify(serializeFilters(filters))
  );
  localStorage.setItem("tundraColorMode", state.colorMode);
});

export default useTundraStore;
