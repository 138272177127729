import { ScheduleProgram } from "../../../types/ScheduleProgram";
import { Channel } from "../../../types/Channel";
import { Affiliate } from "../../../types/Affiliate";
import useTundraStore from "../../../store/useTundraStore";
import { splitProgramsByDay } from "../../../utils/splitProgramsByDay";
import splitProgramsByChannel from "../../../utils/splitProgramsByChannel";
import markConflictingEvents from "../../../utils/markConflictingEvents";
import {
  BlockHeader,
  Item as EmissionItem,
  ItemHeader,
  Items,
} from "../../../components/EmissionsCalendar/EmissionsCalendar";
import { Box, Typography } from "@mui/material";
import Tooltip from "../../../components/Tooltip/Tooltip";
import { getChannelKey } from "../../../utils/getChannelKey";
import getChannelLabel from "../../../utils/getChannelLabel";
import Day from "../../../components/Day/Day";
import EmissionsWithScale from "../../../components/EmissionsCalendar/EmissionsWithScale";
import formatWeekNumber from "../../../utils/formatWeekNumber";
import formatDay from "../../../utils/formatDay";

export const EmissionsByDate = ({
  programs,
  channels,
}: {
  programs: ScheduleProgram[];
  channels: { channels: Channel[]; affiliates: Affiliate[] };
}) => {
  const pickerStartDate = useTundraStore((state) => state.pickerStartDate);
  const pickerEndDate = useTundraStore((state) => state.pickerEndDate);
  const startOfDay = useTundraStore((state) => state.startOfDay);
  const uiChannels = useTundraStore((state) => state.uiChannels);
  const baseWidth = useTundraStore((state) => state.baseWidth);

  const programsByDay = splitProgramsByDay(
    programs,
    pickerStartDate,
    pickerEndDate,
    startOfDay
  );

  const programsByDateByChannel = programsByDay.map(({ date, programs }) => ({
    date,
    programsByChannel: splitProgramsByChannel(programs, uiChannels).map(
      ({ channel, programs }) => ({
        channel,
        programs: markConflictingEvents(programs),
      })
    ),
  }));

  return (
    <EmissionsWithScale
      itemWidth={uiChannels.length * baseWidth + 30}
      numberOfItems={programsByDateByChannel.length}
      startOfDay={startOfDay}
    >
      {({ columnIndex, style }) => {
        const { date, programsByChannel } =
          programsByDateByChannel[columnIndex];
        return (
          <Box marginRight="24px" key={date} style={style}>
            <BlockHeader>
              <Tooltip title={`${formatWeekNumber(date)} week`}>
                <Typography fontSize={18} fontWeight="bold">
                  {formatDay(date)}
                </Typography>
              </Tooltip>
            </BlockHeader>
            <Items>
              {programsByChannel.map(({ channel, programs }) => {
                return (
                  <EmissionItem key={getChannelKey(channel)} width={baseWidth}>
                    <ItemHeader>
                      <Typography
                        fontWeight="bold"
                        color="text.primary"
                        display="block"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        width="100%"
                      >
                        {getChannelLabel(channel, channels)}
                      </Typography>
                    </ItemHeader>
                    <Day
                      programs={programs}
                      startOfDay={startOfDay}
                      date={date}
                    />
                  </EmissionItem>
                );
              })}
            </Items>
          </Box>
        );
      }}
    </EmissionsWithScale>
  );
};
