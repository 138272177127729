import { useQuery } from "@tanstack/react-query";
import { ScheduleProgram } from "../types/ScheduleProgram";
import { ProgramDetails } from "../types/ProgramDetails";

const EMISSIONS_API_URL = process.env.REACT_APP_EMISSIONS_API;

const getProgramDetails = (
  id: number,
  channelId: number,
  languageId: number,
  supportId: number
): Promise<ProgramDetails> => {
  return fetch(
    EMISSIONS_API_URL +
      "/details/" +
      id +
      "/" +
      channelId +
      "/" +
      languageId +
      "/" +
      supportId
  ).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
};

const useEmissionDetails = (program: ScheduleProgram, supportId: number) => {
  return useQuery({
    queryKey: [
      "programDetails",
      program.valEmiId,
      program.channelId,
      program.languageId,
      supportId,
    ],
    cacheTime: 24 * 60 * 1000,
    queryFn: () =>
      getProgramDetails(
        program.valEmiId,
        program.channelId,
        program.languageId,
        supportId
      ),
  });
};

export default useEmissionDetails;
