import useTundraStore from "../../store/useTundraStore";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import ConnectedBroadcastTypesPicker from "../../components/BroadcastTypePicker/ConnectedBroadcastTypesPicker";
import { Close, DeleteOutline } from "@mui/icons-material";
import GridDisplayPicker from "../../components/GridDisplayPicker/GridDisplayPicker";
import ClassificationPicker from "../../components/ClassificationPicker/ClassificationPicker";
import { ClassifierKind } from "../../hooks/useClassifiers";
import { GridSearch } from "./GridSearch";
import ConnectedByDayByChannelToggle from "../../components/ByDayByChannelToggle/ConnectedByDayByChannelToggle";
import ConnectedRegularChannelsPicker from "../../components/RegularChannelsPicker/ConnectedRegularChannelsPicker";
import ConnectedDateRangePicker from "../../components/DateRangePicker/ConnectedDateRangePicker";

const FiltersSectionHeader = ({ children }: { children: string }) => {
  return (
    <Typography
      fontSize="18px"
      fontWeight={700}
      letterSpacing="0.03em"
      marginBottom="16px"
    >
      {children}
    </Typography>
  );
};

const GridFiltersModal = ({ onClose }: { onClose: () => void }) => {
  const gridBroadcastId = useTundraStore((state) => state.gridBroadcastId);
  const setGridBroadcastId = useTundraStore(
    (state) => state.setGridBroadcastId
  );
  const gridValEmiId = useTundraStore((state) => state.gridValEmiId);
  const setGridValEmiId = useTundraStore((state) => state.setGridValEmiId);
  const gridProgramId = useTundraStore((state) => state.gridProgramId);
  const setGridProgramId = useTundraStore((state) => state.setGridProgramId);
  const gridSPEVId = useTundraStore((state) => state.gridSPEVId);
  const setGridSPEVId = useTundraStore((state) => state.setGridSPEVId);
  const classifiersSports = useTundraStore((state) => state.classifiersSports);
  const classifiersEvents = useTundraStore((state) => state.classifiersEvents);
  const classifiersCompetitions = useTundraStore(
    (state) => state.classifiersCompetitions
  );

  const setClassifiersSports = useTundraStore(
    (state) => state.setClassifiersSports
  );
  const setClassifiersEvents = useTundraStore(
    (state) => state.setClassifiersEvents
  );
  const setClassifiersCompetitions = useTundraStore(
    (state) => state.setClassifiersCompetitions
  );

  const resetFilters = () => {
    setGridValEmiId("");
    setGridBroadcastId("");
    setGridValEmiId("");
    setGridSPEVId("");
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={onClose}
        sx={{
          "& .MuiDialog-paper": {
            width: "700px",
            maxWidth: "700px",
          },
          "& .MuiDialogContent-root": {
            width: "700px",
          },
        }}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Filters
          <IconButton onClick={onClose}>
            <Close sx={{ color: "primary.main", fontSize: "22px" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
            <Box>
              <GridSearch />
            </Box>
            <Stack direction="row" spacing={2}>
              <ConnectedDateRangePicker />
              <ConnectedByDayByChannelToggle />
            </Stack>
            <Box>
              <Box sx={{ display: "flex", gap: "16px" }}>
                <ConnectedRegularChannelsPicker />
                <ConnectedBroadcastTypesPicker />
                <GridDisplayPicker />
              </Box>
            </Box>
            <Box>
              <FiltersSectionHeader>Classification</FiltersSectionHeader>
              <Box sx={{ display: "flex", gap: "16px" }}>
                <ClassificationPicker
                  kind={ClassifierKind.Sport}
                  value={classifiersSports}
                  onChange={setClassifiersSports}
                />
                <ClassificationPicker
                  kind={ClassifierKind.Competition}
                  value={classifiersCompetitions}
                  onChange={setClassifiersCompetitions}
                />
                <ClassificationPicker
                  kind={ClassifierKind.Event}
                  value={classifiersEvents}
                  onChange={setClassifiersEvents}
                />
              </Box>
            </Box>
            <Box>
              <FiltersSectionHeader>Program Ids</FiltersSectionHeader>
              <Box sx={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
                <TextField
                  value={gridBroadcastId}
                  label="Broadcast ID"
                  onChange={(e) => setGridBroadcastId(e.target.value)}
                />
                <TextField
                  value={gridValEmiId}
                  label="ValEmiId"
                  onChange={(e) => setGridValEmiId(e.target.value)}
                />
                <TextField
                  value={gridProgramId}
                  label="Program Id"
                  onChange={(e) => setGridProgramId(e.target.value)}
                />
                <TextField
                  value={gridSPEVId}
                  label="SPEV id"
                  onChange={(e) => setGridSPEVId(e.target.value)}
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "background.default",
          }}
        >
          <Button
            variant="text"
            onClick={resetFilters}
            startIcon={<DeleteOutline />}
            color="secondary"
          >
            Reset Filters
          </Button>

          <Box sx={{ display: "flex", gap: "8px" }}>
            <Button variant="contained" onClick={onClose} color="secondary">
              Apply
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GridFiltersModal;
