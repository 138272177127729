import useTundraStore from "../store/useTundraStore";
import { useQuery } from "@tanstack/react-query";
import { SerializedFilter } from "../types/SerializedFilter";

const GET_FILTERS_API = process.env.REACT_APP_GET_FILTERS_API as string;

const getFilters = (
  userEmail?: string,
  authToken?: string
): Promise<{ name: string; id: number; filter: SerializedFilter }[]> => {
  if (!userEmail || !authToken) {
    return new Promise(() => {});
  }

  const params = new URLSearchParams();
  params.set("email", userEmail || "");
  params.set("token", authToken || "");

  return fetch(GET_FILTERS_API + `?${params.toString()}`)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return res.json();
    })
    .then((data) => {
      if (data.status === 403) {
        throw new Error("Auth error when loading filters");
      }
      return data;
    })
    .then((filters) => {
      console.log("filters loaded", filters);
      return (filters || []).map(({ name, jsonContent, id }: any) => ({
        name,
        id,
        filter: JSON.parse(jsonContent),
      }));
    });
};

const useFilters = () => {
  const userEmail = useTundraStore((state) => state.userEmail);
  const authToken = useTundraStore((state) => state.authToken);

  return useQuery({
    queryKey: ["filtersData", userEmail, authToken],
    queryFn: () => getFilters(userEmail, authToken),
  });
};

export default useFilters;
