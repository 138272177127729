import useTundraStore from "../store/useTundraStore";
import { useMutation } from "@tanstack/react-query";

const SAVE_CHANGES_SUBSCRIPTION_API = process.env
  .REACT_APP_CHANGES_SAVE_SUBSCRIPTION_API as string;

const useDeleteChangesSubscription = () => {
  const authToken = useTundraStore((state) => state.authToken);
  const userEmail = useTundraStore((state) => state.userEmail);

  const params = new URLSearchParams();
  params.set("email", userEmail || "");
  params.set("token", authToken || "");

  return useMutation((id: number) =>
    fetch(SAVE_CHANGES_SUBSCRIPTION_API + `/${id}?${params.toString()}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return res.json();
    })
  );
};

export default useDeleteChangesSubscription;
