import { useQuery } from "@tanstack/react-query";
import { Channel } from "../types/Channel";
import { Affiliate } from "../types/Affiliate";
import affiliatesToChannels from "../utils/affiliatesToChannels";
// import sortChannelsByOrder from "../utils/sortChannelsByOrder";

const CHANNELS_API_URL = process.env.REACT_APP_CHANNELS_API as string;

const getChannels = (): Promise<{
  channels: Channel[];
  affiliates: Affiliate[];
  affiliatesAsChannels: Channel[];
}> => {
  return fetch(CHANNELS_API_URL)
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return res.json();
    })
    .then(({ channels, affiliates }) => ({
      channels: channels,
      affiliatesAsChannels: affiliatesToChannels(affiliates),
      affiliates,
    }));
};

const useChannels = () => {
  return useQuery({
    queryKey: ["channelsData"],
    cacheTime: 24 * 60 * 1000,
    queryFn: () => getChannels(),
  });
};

export default useChannels;
