import { Channel } from "../types/Channel";

const getChannelById = (
  channelId: number,
  channels: Channel[] = []
): Channel | undefined => {
  for (let channel of channels) {
    if (channel.id === channelId) {
      return channel;
    }
    if (channel.children) {
      const innerChannel = getChannelById(channelId, channel.children);
      if (innerChannel) {
        return innerChannel;
      }
    }
  }
};

export default getChannelById;
