const LABELS: { [key: number]: string } = {
  1: "LIVE",
  2: "HLTS",
  3: "L/RX",
  4: "RX",
  5: "REPLAY",
  6: "VTR",
  7: "RELI",
  8: "REPL*",
  9: "LISH",
  10: "RXF",
  11: "MAGAZINE",
};

const getBroadcastShortLabel = (broadcastId: number): string => {
  return LABELS[broadcastId] || "UNKNOWN";
};

export default getBroadcastShortLabel;
