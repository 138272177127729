import { HistoricChange } from "../../types/HistoricChange";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const HistoricChangesModal = ({
  changes,
  onClose,
}: {
  changes: HistoricChange[];
  onClose: () => void;
}) => {
  return (
    <Dialog open={true} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Historic Changes
        <IconButton onClick={onClose}>
          <Close sx={{ color: "primary.main", fontSize: "22px" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow sx={{ whiteSpace: "nowrap" }}>
              <TableCell>Change Date</TableCell>
              <TableCell>Type of Change</TableCell>
              <TableCell>Broadcast</TableCell>
              <TableCell>Old Timing</TableCell>
              <TableCell>New Timing</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {changes.map((change, i) => (
              <TableRow key={i}>
                <TableCell>{change.changeDate}</TableCell>
                <TableCell>{change.typeOfChange}</TableCell>
                <TableCell>{change.broadcast}</TableCell>
                <TableCell>{change.oldTiming}</TableCell>
                <TableCell>{change.newTiming}</TableCell>
                <TableCell>{change.status}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};

export default HistoricChangesModal;
