import { useMutation } from "@tanstack/react-query";
import useTundraStore from "../store/useTundraStore";
import { SerializedFilter } from "../types/SerializedFilter";

const SAVE_FILTER_API = process.env.REACT_APP_SAVE_FILTER_API as string;

const useSaveFilter = () => {
  const userEmail = useTundraStore((state) => state.userEmail);
  const authToken = useTundraStore((state) => state.authToken);
  const params = new URLSearchParams();
  params.set("email", userEmail || "");
  params.set("token", authToken || "");

  return useMutation(
    ({
      filterName,
      filter,
    }: {
      filterName: string;
      filter: SerializedFilter;
    }) =>
      fetch(`${SAVE_FILTER_API}?${params.toString()}`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: filterName,
          jsonContent: JSON.stringify(filter),
        }),
      }).then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res.json();
      })
  );
};

export default useSaveFilter;
