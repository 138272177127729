import { Button } from "@mui/material";
import { format } from "date-fns";
import { SaveOutlined } from "@mui/icons-material";

const ExcelExport = ({ url, data }: { url: string; data: {} }) => {
  const download = async () => {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    const arrayBuffer = await res.arrayBuffer();

    var a = document.createElement("a");
    document.body.appendChild(a);
    let blob = new Blob([arrayBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    let fileURL = window.URL.createObjectURL(blob);
    a.href = fileURL;
    a.download = "Grid_" + format(new Date(), "dd.MM.yyyy") + ".xlsx";
    a.click();
    window.URL.revokeObjectURL(fileURL);
  };

  return (
    <Button
      variant="contained"
      sx={{
        minWidth: "180px",
      }}
      startIcon={<SaveOutlined />}
      onClick={download}
    >
      Save For Excel
    </Button>
  );
};

export default ExcelExport;
