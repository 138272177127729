import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";

const AffiliatesToggle = ({
  value,
  onChange,
}: {
  value: boolean;
  onChange: (showAffiliates: boolean) => void;
}) => {
  return (
    <Box>
      <ToggleButtonGroup
        sx={{ height: "40px" }}
        value={value ? "affiliates" : "eurosport"}
        exclusive
        onChange={(event, value) => value && onChange(value === "affiliates")}
      >
        <ToggleButton value="eurosport">Eurosport</ToggleButton>
        <ToggleButton value="affiliates">Affiliates</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default AffiliatesToggle;
