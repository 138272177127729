import useTundraStore, {
  DEFAULT_FILTERS,
  TundraFilters,
} from "../../../store/useTundraStore";
import Dialog from "@mui/material/Dialog";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import ClassificationPicker from "../../../components/ClassificationPicker/ClassificationPicker";
import { ClassifierKind } from "../../../hooks/useClassifiers";
import { useState } from "react";
import FilterNameModal from "./FilterNameModal";
import RestoreFilters from "./RestoreFilters";
import DateRangePicker from "../../../components/DateRangePicker/DateRangePicker";
import FilterResetModal from "./FilterResetModal";
import useSaveFilter from "../../../hooks/useSaveFilter";
import useFilters from "../../../hooks/useFilters";
import useDeleteFilter from "../../../hooks/useDeleteFilter";
import { Close, DeleteOutline } from "@mui/icons-material";
import { SEARCH_INPUT_OPTIONS } from "../constants";
import SearchInput from "../../../components/SearchInput/SearchInput";
import { serializeFilters } from "../../../utils/serializeFilters";
import BroadcastTypesPicker from "../../../components/BroadcastTypePicker/BroadcastTypesPicker";
import AffiliatesToggle from "../../../components/AffiliatesToggle/AffiliatesToggle";
import ByDayByChannelToggle from "../../../components/ByDayByChannelToggle/ByDayByChannelToggle";
import MidnightToggle from "../../../components/MidnightToggle/MidnightToggle";
import TitleTypePicker from "../../../components/TitleTypePicker/TitleTypePicker";
import RegularChannelsPicker from "../../../components/RegularChannelsPicker/RegularChannelsPicker";
import AffiliatePicker from "../../../components/AffiliatePicker/AffiliatePicker";
import LanguagePicker from "../../../components/LanguagePicker/LanguagePicker";
import CountriesPicker from "../../../components/CountriesPicker/CountriesPicker";
import { SerializedFilter } from "../../../types/SerializedFilter";
import { deserializeFilters } from "../../../utils/deserializeFilters";

const FiltersSectionHeader = ({ children }: { children: string }) => {
  return (
    <Typography
      fontSize="18px"
      fontWeight={700}
      letterSpacing="0.03em"
      marginBottom="16px"
    >
      {children}
    </Typography>
  );
};

const ScheduleFiltersModal = ({ onClose }: { onClose: () => void }) => {
  const savedFilter: TundraFilters = useTundraStore((state) =>
    state.getFilter()
  );
  const resetFilter = useTundraStore((state) => state.resetFilter);
  const isAuthenticated = useTundraStore((state) => state.isAuthenticated);

  const applyFilter = useTundraStore((state) => state.applyFilter);

  const [filter, setFilter] = useState<TundraFilters>(savedFilter);

  const [isSaveOpen, setSaveOpen] = useState(false);
  const [isResetOpen, setResetOpen] = useState(false);

  const filtersQuery = useFilters();
  const saveFilterMutation = useSaveFilter();
  const deleteFilterMutation = useDeleteFilter();

  const onApply = () => {
    applyFilter(filter);
    onClose();
  };

  const onReset = () => {
    setResetOpen(false);
    resetFilter();
    setFilter(DEFAULT_FILTERS);
  };

  const saveFilter = (name: string) => {
    saveFilterMutation.mutate(
      { filterName: name, filter: serializeFilters(filter) },
      {
        onSuccess: () => {
          filtersQuery.refetch();
        },
      }
    );
  };

  const deleteFilter = (idToDelete: number) => {
    deleteFilterMutation.mutate(idToDelete, {
      onSuccess: () => {
        filtersQuery.refetch();
      },
    });
  };

  const restoreFilter = (filterToRestore: SerializedFilter) => {
    setFilter(deserializeFilters(filterToRestore));
  };

  return (
    <>
      <Dialog
        open={true}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "726px",
            },
          },
        }}
      >
        <DialogTitle>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              Filters
              {isAuthenticated && filtersQuery.data && (
                <RestoreFilters
                  savedFilters={filtersQuery.data}
                  onRestore={restoreFilter}
                  onDelete={deleteFilter}
                />
              )}
              {isAuthenticated &&
                (filtersQuery.isLoading || deleteFilterMutation.isLoading) && (
                  <CircularProgress size="small" />
                )}
              {isAuthenticated && filtersQuery.isError && (
                <Alert severity="error" variant="filled">
                  Error loading filters
                </Alert>
              )}
            </Box>
            <IconButton onClick={onClose}>
              <Close sx={{ color: "primary.main", fontSize: "22px" }} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Box>
              <FiltersSectionHeader>Search by feature</FiltersSectionHeader>
              <SearchInput
                searchType={filter.searchType}
                onTypeChange={(searchType) =>
                  setFilter((filter) => ({ ...filter, searchType }))
                }
                value={filter.searchString}
                onChange={(searchString) =>
                  setFilter((filter) => ({ ...filter, searchString }))
                }
                options={SEARCH_INPUT_OPTIONS}
              />
            </Box>
            <Box>
              <FiltersSectionHeader>Date</FiltersSectionHeader>
              <DateRangePicker
                pickerEndDate={filter.pickerEndDate}
                pickerStartDate={filter.pickerStartDate}
                setPickerDates={(pickerStartDate, pickerEndDate) =>
                  setFilter((filter) => ({
                    ...filter,
                    pickerStartDate,
                    pickerEndDate,
                  }))
                }
              />
            </Box>
            <Box>
              <FiltersSectionHeader>Display</FiltersSectionHeader>
              <Box sx={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
                <AffiliatesToggle
                  value={filter.showAffiliates}
                  onChange={(showAffiliates) =>
                    setFilter((filter) => ({
                      ...filter,
                      showAffiliates,
                    }))
                  }
                />
                <ByDayByChannelToggle
                  value={filter.organizeBy}
                  onChange={(organizeBy) =>
                    setFilter((filter) => ({
                      ...filter,
                      organizeBy,
                    }))
                  }
                />
                <MidnightToggle
                  value={filter.startOfDay}
                  onChange={(startOfDay) =>
                    setFilter((filter) => ({
                      ...filter,
                      startOfDay,
                    }))
                  }
                />
              </Box>
            </Box>
            <Box>
              <FiltersSectionHeader>Standard Filters</FiltersSectionHeader>
              <Box
                sx={{
                  marginBottom: "16px",
                  display: "flex",
                  gap: "16px",
                }}
              >
                <TitleTypePicker
                  value={filter.titleId}
                  onChange={(titleId) =>
                    setFilter((filter) => ({ ...filter, titleId }))
                  }
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: "16px",
                  flexWrap: "wrap",
                  maxWidth: "500px",
                }}
              >
                {!filter.showAffiliates && (
                  <RegularChannelsPicker
                    value={filter.uiChannels}
                    onChange={(uiChannels) =>
                      setFilter((filter) => ({ ...filter, uiChannels }))
                    }
                  />
                )}
                {filter.showAffiliates && (
                  <AffiliatePicker
                    value={filter.uiChannels}
                    onChange={(uiChannels) =>
                      setFilter((filter) => ({ ...filter, uiChannels }))
                    }
                  />
                )}
                <LanguagePicker
                  value={filter.uiChannels}
                  onChange={(uiChannels) =>
                    setFilter((filter) => ({ ...filter, uiChannels }))
                  }
                />
                <CountriesPicker
                  value={filter.countries}
                  onChange={(countries) =>
                    setFilter((filter) => ({ ...filter, countries }))
                  }
                />
                <BroadcastTypesPicker
                  value={filter.broadcastTypes}
                  onChange={(broadcastTypes) =>
                    setFilter((filter) => ({ ...filter, broadcastTypes }))
                  }
                />
              </Box>
            </Box>
            <Box>
              <FiltersSectionHeader>Classification</FiltersSectionHeader>
              <Box sx={{ display: "flex", gap: "16px", flexWrap: "wrap" }}>
                <ClassificationPicker
                  kind={ClassifierKind.Sport}
                  value={filter.classifiersSports}
                  onChange={(classifiersSports) =>
                    setFilter((filter) => ({ ...filter, classifiersSports }))
                  }
                />
                <ClassificationPicker
                  kind={ClassifierKind.Competition}
                  value={filter.classifiersCompetitions}
                  onChange={(classifiersCompetitions) =>
                    setFilter((filter) => ({
                      ...filter,
                      classifiersCompetitions,
                    }))
                  }
                />
                <ClassificationPicker
                  kind={ClassifierKind.Event}
                  value={filter.classifiersEvents}
                  onChange={(classifiersEvents) =>
                    setFilter((filter) => ({ ...filter, classifiersEvents }))
                  }
                />
              </Box>
            </Box>
            {saveFilterMutation.isError && (
              <Alert severity="error" variant="filled">
                Error saving filter
              </Alert>
            )}
            {deleteFilterMutation.isError && (
              <Alert severity="error" variant="filled">
                Error deleting filter
              </Alert>
            )}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "background.default",
          }}
        >
          <Button
            variant="text"
            color="secondary"
            onClick={() => setResetOpen(true)}
            startIcon={<DeleteOutline />}
          >
            Remove All
          </Button>
          {isAuthenticated && saveFilterMutation.isLoading && (
            <CircularProgress />
          )}

          <Box sx={{ display: "flex", gap: "8px" }}>
            {isAuthenticated && !saveFilterMutation.isLoading && (
              <Button variant="contained" onClick={() => setSaveOpen(true)}>
                Save Filters
              </Button>
            )}
            <Button variant="contained" onClick={onApply}>
              Apply
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
      {isSaveOpen && (
        <FilterNameModal
          isOpen={isSaveOpen}
          selectedNames={(filtersQuery?.data || []).map(({ name }) => name)}
          onClose={() => setSaveOpen(false)}
          onSubmit={(name) => {
            setSaveOpen(false);
            saveFilter(name);
          }}
        />
      )}
      {isResetOpen && (
        <FilterResetModal
          isOpen={isResetOpen}
          onClose={() => setResetOpen(false)}
          onSubmit={onReset}
        />
      )}
    </>
  );
};

export default ScheduleFiltersModal;
