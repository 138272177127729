import { useQuery } from "@tanstack/react-query";
import { Country } from "../types/Country";

const COUNTRIES_API_URL = process.env.REACT_APP_COUNTRIES_API as string;

const getCountries = (): Promise<Country[]> => {
  return fetch(COUNTRIES_API_URL).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
};

const useCountries = () => {
  return useQuery({
    queryKey: ["countriesData"],
    cacheTime: 24 * 60 * 1000,
    queryFn: () => getCountries(),
  });
};

export default useCountries;
