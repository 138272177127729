import { ScheduleProgram } from "../../../types/ScheduleProgram";
import { Channel } from "../../../types/Channel";
import { Affiliate } from "../../../types/Affiliate";
import useTundraStore from "../../../store/useTundraStore";
import {
  getDatesBetween,
  splitProgramsByDay,
} from "../../../utils/splitProgramsByDay";
import splitProgramsByChannel from "../../../utils/splitProgramsByChannel";
import markConflictingEvents from "../../../utils/markConflictingEvents";
import {
  BlockHeader,
  ItemHeader,
  Items,
} from "../../../components/EmissionsCalendar/EmissionsCalendar";
import { Box, Typography } from "@mui/material";
import { getChannelKey } from "../../../utils/getChannelKey";
import getChannelLabel from "../../../utils/getChannelLabel";
import Tooltip from "../../../components/Tooltip/Tooltip";
import Day from "../../../components/Day/Day";
import EmissionsWithScale from "../../../components/EmissionsCalendar/EmissionsWithScale";
import formatWeekNumber from "../../../utils/formatWeekNumber";
import formatDay from "../../../utils/formatDay";
import { useMemo } from "react";

export const EmissionsByChannel = ({
  programs,
  channels,
}: {
  programs: ScheduleProgram[];
  channels: { channels: Channel[]; affiliates: Affiliate[] };
}) => {
  const pickerStartDate = useTundraStore((state) => state.pickerStartDate);
  const pickerEndDate = useTundraStore((state) => state.pickerEndDate);
  const uiChannels = useTundraStore((state) => state.uiChannels);
  const startOfDay = useTundraStore((state) => state.startOfDay);
  const baseWidth = useTundraStore((state) => state.baseWidth);

  const numberOfDates = getDatesBetween(pickerStartDate, pickerEndDate).length;
  const programsByChannel = useMemo(
    () => splitProgramsByChannel(programs, uiChannels),
    [programs, uiChannels]
  );

  const programsByChannelByDate = useMemo(
    () =>
      programsByChannel.map(({ channel, programs }) => ({
        channel,
        programsByDay: splitProgramsByDay(
          programs,
          pickerStartDate,
          pickerEndDate,
          startOfDay
        ).map(({ date, programs }) => ({
          date,
          programs: markConflictingEvents(programs),
        })),
      })),
    [programsByChannel, pickerStartDate, pickerEndDate, startOfDay]
  );

  return (
    <EmissionsWithScale
      itemWidth={numberOfDates * baseWidth + 30}
      numberOfItems={programsByChannelByDate.length}
      startOfDay={startOfDay}
    >
      {({ columnIndex, style }) => {
        const { channel, programsByDay } = programsByChannelByDate[columnIndex];
        return (
          <Box marginRight="24px" key={getChannelKey(channel)} style={style}>
            <BlockHeader>
              <Typography fontSize={18} fontWeight="bold">
                {getChannelLabel(channel, channels)}
              </Typography>
            </BlockHeader>
            <Items>
              {programsByDay.map(({ date, programs }) => {
                return (
                  <Box position="relative" width={`${baseWidth}px`} key={date}>
                    <ItemHeader>
                      <Tooltip title={`${formatWeekNumber(date)} week`}>
                        <Typography fontWeight="bold" color="text.primary">
                          {formatDay(date)}
                        </Typography>
                      </Tooltip>
                    </ItemHeader>
                    <Day
                      programs={programs}
                      startOfDay={startOfDay}
                      date={date}
                    />
                  </Box>
                );
              })}
            </Items>
          </Box>
        );
      }}
    </EmissionsWithScale>
  );
};
