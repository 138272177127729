import { GridProgram } from "../../types/GridProgram";
import { Channel } from "../../types/Channel";
import { Affiliate } from "../../types/Affiliate";
import { splitProgramsByDay } from "../../utils/splitProgramsByDay";
import splitProgramsByChannel from "../../utils/splitProgramsByChannel";
import markConflictingEvents from "../../utils/markConflictingEvents";
import useTundraStore from "../../store/useTundraStore";
import {
  BlockHeader,
  Item as EmissionItem,
  ItemHeader,
  Items,
} from "../../components/EmissionsCalendar/EmissionsCalendar";
import { Box, Typography } from "@mui/material";
import Tooltip from "../../components/Tooltip/Tooltip";
import { getChannelKey } from "../../utils/getChannelKey";
import getChannelLabel from "../../utils/getChannelLabel";
import Day from "../../components/Day/Day";
import EmissionsWithScale from "../../components/EmissionsCalendar/EmissionsWithScale";
import formatWeekNumber from "../../utils/formatWeekNumber";
import formatDay from "../../utils/formatDay";

const GridByDate = ({
  programs,
  channels,
}: {
  programs: GridProgram[];
  channels: { channels: Channel[]; affiliates: Affiliate[] };
}) => {
  const pickerStartDate = useTundraStore((state) => state.pickerStartDate);
  const pickerEndDate = useTundraStore((state) => state.pickerEndDate);
  const uiChannels = useTundraStore((state) => state.uiChannels);
  const gridDisplay = useTundraStore((state) => state.gridDisplay);
  const baseWidth = 200 + gridDisplay.length * 80;

  const shouldShowBreaks = gridDisplay.includes(1);
  const shouldShowFeeds = gridDisplay.includes(2);

  const programsByDay = splitProgramsByDay(
    programs,
    pickerStartDate,
    pickerEndDate,
    "3am"
  );

  const programsByDateByChannel = programsByDay.map(({ date, programs }) => ({
    date,
    programsByChannel: splitProgramsByChannel(programs, uiChannels).map(
      ({ channel, programs }) => ({
        channel,
        programs: markConflictingEvents(programs),
      })
    ),
  }));

  return (
    <EmissionsWithScale
      itemWidth={uiChannels.length * baseWidth + 14}
      numberOfItems={programsByDateByChannel.length}
      startOfDay="3am"
    >
      {({ columnIndex, style }) => {
        const { date, programsByChannel } =
          programsByDateByChannel[columnIndex];
        return (
          <Box position="relative" marginRight="24px" key={date} style={style}>
            <BlockHeader>
              <Tooltip title={`${formatWeekNumber(date)} week`}>
                <Typography fontSize={18} fontWeight="bold">
                  {formatDay(date)}
                </Typography>
              </Tooltip>
            </BlockHeader>
            <Items>
              {programsByChannel.map(({ channel, programs }) => {
                return (
                  <EmissionItem key={getChannelKey(channel)} width={baseWidth}>
                    <ItemHeader>
                      <Typography
                        fontWeight="bold"
                        color="text.primary"
                        display="block"
                        textOverflow="ellipsis"
                        overflow="hidden"
                        width="100%"
                      >
                        {getChannelLabel(channel, channels)}
                      </Typography>
                    </ItemHeader>
                    {gridDisplay.length > 0 && (
                      <Box display="flex" height="24px">
                        <Box width="200px"></Box>
                        {shouldShowBreaks && (
                          <Box width="80px" paddingLeft="3px">
                            <Typography fontSize="14px">Breaks</Typography>
                          </Box>
                        )}
                        {shouldShowFeeds && (
                          <Box width="80px" paddingLeft="3px">
                            <Typography fontSize="14px">Feeds</Typography>
                          </Box>
                        )}
                      </Box>
                    )}
                    <Day
                      programs={programs}
                      startOfDay={"3am"}
                      date={date}
                      gridDisplay={gridDisplay}
                    />
                  </EmissionItem>
                );
              })}
            </Items>
          </Box>
        );
      }}
    </EmissionsWithScale>
  );
};

export default GridByDate;
