import useTundraStore from "../../store/useTundraStore";
import DateRangePicker from "./DateRangePicker";

const ConnectedDateRangePicker = () => {
  const pickerStartDate = useTundraStore((state) => state.pickerStartDate);
  const pickerEndDate = useTundraStore((state) => state.pickerEndDate);
  const setPickerDates = useTundraStore((state) => state.setPickerDates);

  return (
    <DateRangePicker
      pickerStartDate={pickerStartDate}
      pickerEndDate={pickerEndDate}
      setPickerDates={setPickerDates}
    />
  );
};

export default ConnectedDateRangePicker;
