import { UIBroadcastType } from "../types/UIBroadcastType";
import {
  CALENDAR_LIVE,
  CALENDAR_LIVE_ACCENT,
  CALENDAR_PROGRAM,
  CALENDAR_PROGRAM_ACCENT,
  CALENDAR_RELIVE,
  CALENDAR_RELIVE_ACCENT,
  CALENDAR_REPLAY,
  CALENDAR_REPLAY_ACCENT,
  CALENDAR_UNKNOWN,
  CALENDAR_UNKNOWN_ACCENT,
} from "../colors";
import ReplayIcon from "../components/EventIcon/ReplayIcon";
import LiveIcon from "../components/EventIcon/LiveIcon";
import FilmIcon from "../components/EventIcon/FilmIcon";
import ReliveIcon from "../components/EventIcon/ReliveIcon";

export const getEventColorsAndIcon = (
  uiType: UIBroadcastType
): [string, string, React.FunctionComponent | null] => {
  switch (uiType) {
    case UIBroadcastType.REPLAY:
      return [CALENDAR_REPLAY, CALENDAR_REPLAY_ACCENT, ReplayIcon];

    case UIBroadcastType.LIVE:
      return [CALENDAR_LIVE, CALENDAR_LIVE_ACCENT, LiveIcon];

    case UIBroadcastType.PROGRAM:
      return [CALENDAR_PROGRAM, CALENDAR_PROGRAM_ACCENT, FilmIcon];

    case UIBroadcastType.RELIVE:
      return [CALENDAR_RELIVE, CALENDAR_RELIVE_ACCENT, ReliveIcon];

    default:
      return [CALENDAR_UNKNOWN, CALENDAR_UNKNOWN_ACCENT, null];
  }
};
