import useTundraStore from "../../store/useTundraStore";
import { BASIC_HEIGHT } from "../../constants";
import { StartOfDay } from "../../types/StartOfDay";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const getNow = () => dayjs(new Date()).tz("Europe/Paris");

const TimeIndicator = ({
  date,
  startOfDay,
}: {
  date: string;
  startOfDay: StartOfDay;
}) => {
  const zoomLevel = useTundraStore((state) => state.zoomLevel);
  const basicHeight = (BASIC_HEIGHT * zoomLevel) / 100;

  // current time is always in CET
  const [now, setNow] = useState(getNow());

  useEffect(() => {
    const interval = setInterval(() => setNow(getNow()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const normalizedNow = startOfDay === "3am" ? now.subtract(3, "hours") : now;

  if (!dayjs(date).isSame(normalizedNow, "day")) {
    return null;
  }

  const hours = normalizedNow.hour();
  const minutes = normalizedNow.minute();

  const position = (hours + minutes / 60) * basicHeight;

  return (
    <div
      style={{
        position: "absolute",
        top: `${position - 8}px`,
        left: 0,
        right: 0,
        height: "12px",
        zIndex: 11,
        pointerEvents: "none",
        transition: "top 500ms ease-in-out",
        overflow: "hidden",
      }}
    >
      <svg
        width="166"
        height="12"
        viewBox="0 0 166 12"
        fill="none"
        style={{ position: "absolute" }}
      >
        <path
          d="M10 5L0 0.226497V11.7735L10 7V5ZM9 7H166V5H9V7Z"
          fill="#D50032"
        />
      </svg>
      <Box
        sx={{
          position: "absolute",
          backgroundColor: "#D50032",
          top: "5px",
          height: "2px",
          left: 0,
          right: 0,
        }}
      ></Box>
    </div>
  );
};

export default TimeIndicator;
