import useTundraStore from "../../store/useTundraStore";
import { useGridData } from "../../hooks/useGridData";
import { LoadingOrErrorContainer } from "../../components/EmissionsCalendar/EmissionsCalendar";
import { CircularProgress } from "@mui/material";
import { ChannelType } from "../../types/ChannelType";
import useChannels from "../../hooks/useChannels";
import GridByDate from "./GridByDate";
import GridByChannel from "./GridByChannel";

const GridItems = () => {
  const pickerStartDate = useTundraStore((state) => state.pickerStartDate);
  const pickerEndDate = useTundraStore((state) => state.pickerEndDate);
  const uiChannels = useTundraStore((state) => state.uiChannels);

  const broadcastTypes = useTundraStore((state) => state.broadcastTypes);

  const gridBroadcastId = useTundraStore((state) => state.gridBroadcastId);
  const gridValEmiId = useTundraStore((state) => state.gridValEmiId);
  const gridProgramId = useTundraStore((state) => state.gridProgramId);
  const gridSPEVId = useTundraStore((state) => state.gridSPEVId);
  const eventIds = useTundraStore((state) => state.classifiersEvents);
  const sportIds = useTundraStore((state) => state.classifiersSports);
  const competitionIds = useTundraStore(
    (state) => state.classifiersCompetitions
  );
  const gridDisplay = useTundraStore((state) => state.gridDisplay);
  const organizeBy = useTundraStore((state) => state.organizeBy);

  const channelsQuery = useChannels();

  const gridQuery = useGridData(
    pickerStartDate,
    pickerEndDate,
    uiChannels
      .filter((channel) => channel.type === ChannelType.Channel)
      .map(({ id }) => id),
    broadcastTypes,
    gridBroadcastId,
    gridValEmiId,
    gridProgramId,
    gridSPEVId,
    eventIds,
    sportIds,
    competitionIds,
    gridDisplay,
  );

  if (gridQuery.isLoading || channelsQuery.isLoading) {
    return (
      <LoadingOrErrorContainer>
        <CircularProgress />
      </LoadingOrErrorContainer>
    );
  }

  if (
    gridQuery.error ||
    !gridQuery.data ||
    channelsQuery.error ||
    !channelsQuery.data
  )
    return (
      <LoadingOrErrorContainer>
        {"An error has occurred"}
      </LoadingOrErrorContainer>
    );

  if (organizeBy === "byChannel") {
    return (
      <GridByChannel programs={gridQuery.data} channels={channelsQuery.data} />
    );
  }

  return <GridByDate programs={gridQuery.data} channels={channelsQuery.data} />;
};

export default GridItems;
