import { Channel } from "../types/Channel";

const getChannelDefaultLanguage = (
  channelId: number,
  channels: Channel[] = []
): number | undefined => {
  for (let channel of channels) {
    if (channel.id === channelId) return channel.defaultLanguageId;

    const language = getChannelDefaultLanguage(channelId, channel.children);

    if (language) {
      return language;
    }
  }

  return undefined;
};

export default getChannelDefaultLanguage;
