import { styled } from "@mui/material";

const Container = styled("div")(({ theme }) => ({
  width: "100%",
  isolation: "isolate",
  position: "absolute",
  background: theme.palette.background.default,
  left: 0,
  right: 0,
  bottom: 0,
  top: "48px",
}));

export default Container;
