import ZoomLevel from "../../components/ZoomLevel/ZoomLevel";
import { Box, CircularProgress, IconButton } from "@mui/material";
import useTundraStore from "../../store/useTundraStore";
import useTitles from "../../hooks/useTitles";
import TundraAppBar from "../../components/TundraAppBar/TundraAppBar";
import React, { useEffect } from "react";
import Toolbar from "../../components/Toolbar/Toolbar";
import GridItems from "./GridItems";
import Container from "../../components/Container/Container";
import { TuneOutlined } from "@mui/icons-material";
import GridFiltersModal from "./GridFiltersModal";
import { LoadingOrErrorContainer } from "../../components/EmissionsCalendar/EmissionsCalendar";
import GridDisplayPicker from "../../components/GridDisplayPicker/GridDisplayPicker";
import ConnectedByDayByChannelToggle from "../../components/ByDayByChannelToggle/ConnectedByDayByChannelToggle";
import { GridExcelExport } from "./GridExcelExport";
import { GridSearch } from "./GridSearch";
import GridHelp from "./GridHelp";
import ConnectedRegularChannelsPicker from "../../components/RegularChannelsPicker/ConnectedRegularChannelsPicker";
import ConnectedDateRangePicker from "../../components/DateRangePicker/ConnectedDateRangePicker";

const Grid = () => {
  const setZoomLevel = useTundraStore((state) => state.setZoomLevel);
  const setSearchType = useTundraStore((state) => state.setSearchType);
  const setSearchString = useTundraStore((state) => state.setSearchString);
  const [showFilters, setShowFilters] = React.useState(false);

  const titlesQuery = useTitles();

  useEffect(() => {
    setZoomLevel(295);
    setSearchType("all");
    setSearchString("");
  });

  if (titlesQuery.isLoading)
    return (
      <Box
        sx={{
          position: "fixed",
          top: "200px",
          left: "0",
          right: "0",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <CircularProgress />
      </Box>
    );

  if (titlesQuery.error || !titlesQuery.data) {
    return (
      <LoadingOrErrorContainer>Error loading titles</LoadingOrErrorContainer>
    );
  }

  return (
    <>
      <TundraAppBar pageName="Grid" innerComponent={<GridHelp />} />
      <Container>
        <Toolbar
          actionComponent={
            <IconButton
              sx={{ marginRight: "16px", marginLeft: "8px" }}
              onClick={() => setShowFilters(true)}
            >
              <TuneOutlined sx={{ fontSize: "24px", color: "primary.main" }} />
            </IconButton>
          }
        >
          <GridSearch />
          <ConnectedDateRangePicker />
          <ConnectedRegularChannelsPicker />
          <ConnectedByDayByChannelToggle />
          <GridDisplayPicker />
          <GridExcelExport />
        </Toolbar>
        <GridItems />
        {showFilters && (
          <GridFiltersModal onClose={() => setShowFilters(false)} />
        )}
        <Box sx={{ position: "fixed", right: 0, top: "120px", zIndex: "22" }}>
          <ZoomLevel minZoom={100} maxZoom={600} />
        </Box>
      </Container>
    </>
  );
};

export default Grid;
