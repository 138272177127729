import { MultiSelectPicker } from "@shared/eurosport-ui";
import { PublishedWithChanges } from "@mui/icons-material";

export const TYPES_OF_CHANGE = [
  { name: "Window creation", id: 1 },
  { name: "Window change", id: 2 },
  { name: "Program creation", id: 3 },
  { name: "Program change", id: 4 },
  { name: "Program suppression", id: 5 },
];

const TypeOfChangePicker = ({
  value,
  onChange,
}: {
  value: number[];
  onChange: (v: number[]) => void;
}) => {
  return (
    <MultiSelectPicker
      label="Types of change"
      value={value}
      icon={<PublishedWithChanges />}
      onChange={onChange}
      options={TYPES_OF_CHANGE}
      getOptionLabel={({ name }) => name}
      getOptionId={({ id }) => id}
      sx={{ whiteSpace: "nowrap", minWidth: "220px" }}
    />
  );
};

export default TypeOfChangePicker;
