import { Program } from "../types/Program";

// half second conflict should not be marked as conflict
const isConflicting = (newStartTime: Date, previousEndTime: Date) => {
  return newStartTime.getTime() < previousEndTime.getTime() - 500;
};

const markConflictingEvents = (programs: Program[]): Program[] => {
  const markedPrograms: Program[] = [];

  let currentEndTime: Date | undefined;
  let currentDepth = 0;

  for (let program of programs) {
    if (currentEndTime && isConflicting(program.startDate, currentEndTime)) {
      currentDepth++;
    } else {
      currentDepth = 0;
    }
    currentEndTime = program.endDate;
    markedPrograms.push(
      currentDepth === 0
        ? program
        : { ...program, conflictingLevel: currentDepth }
    );
  }
  return markedPrograms;
};

export default markConflictingEvents;
