import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { OrganizeScheduleBy } from "../../types/OrganizeScheduleBy";

const ByDayByChannelToggle = ({
  value,
  onChange,
}: {
  value: OrganizeScheduleBy;
  onChange: (organizeBy: OrganizeScheduleBy) => void;
}) => {
  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={(event, value) => value && onChange(value)}
    >
      <ToggleButton value="byChannel">By&nbsp;Channel</ToggleButton>
      <ToggleButton value="byDate">By&nbsp;Date</ToggleButton>
    </ToggleButtonGroup>
  );
};

export default ByDayByChannelToggle;
