import { forwardRef, Fragment } from "react";
import styled from "@emotion/styled";
import useTundraStore from "../../store/useTundraStore";
import { BASIC_HEIGHT } from "../../constants";
import { Box, Typography } from "@mui/material";
import { StartOfDay } from "../../types/StartOfDay";

const Hour = styled.div`
  z-index: 20;
  left: 0px;
  width: 100%;
  position: relative;
  padding-right: 10px;
  top: -9px;
`;

const formatHour = (hour: number) => {
  return `${hour < 10 ? "0" : ""}${hour}`;
};

const Scale = forwardRef<HTMLDivElement, { startOfDay: StartOfDay }>(
  ({ startOfDay }, ref) => {
    const zoomLevel = useTundraStore((state) => state.zoomLevel);
    const basicHeight = BASIC_HEIGHT * (zoomLevel / 100);

    const showHalfHours = zoomLevel >= 120;
    const showQuatreHours = zoomLevel >= 260;

    let height = showHalfHours ? basicHeight / 2 : basicHeight;
    height = showQuatreHours ? height / 2 : height;

    return (
      <Box
        sx={{
          width: "50px",
          display: "block",
          position: "absolute",
          zIndex: 20,
          left: 0,
          background: "background.default",
          color: "text.primary",
          paddingTop: "60px",
          paddingLeft: "10px",
          height: basicHeight * 24,
        }}
        ref={ref}
      >
        {Array.from(Array(24).keys()).map((dateHour) => {
          const hour = startOfDay === "3am" ? (dateHour + 3) % 24 : dateHour;
          return (
            <Fragment key={hour}>
              <Hour style={{ height }}>
                <Typography fontSize={13}>{formatHour(hour)}:00</Typography>
              </Hour>
              {showQuatreHours && (
                <Hour style={{ height }}>
                  <Typography fontSize={13}>{formatHour(hour)}:15</Typography>
                </Hour>
              )}
              {showHalfHours && (
                <Hour style={{ height }}>
                  <Typography fontSize={13}>{formatHour(hour)}:30</Typography>
                </Hour>
              )}
              {showQuatreHours && (
                <Hour style={{ height }}>
                  <Typography fontSize={13}>{formatHour(hour)}:45</Typography>
                </Hour>
              )}
            </Fragment>
          );
        })}
      </Box>
    );
  }
);

export default Scale;
