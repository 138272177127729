import { Channel } from "../../types/Channel";

export const filterChannels = (
  channels: Channel[],
  typeId: number,
  filterString: string
) => {
  const channelsByType = channels?.filter((channel: Channel) => {
    return channel.channelTypeId === typeId;
  });

  return channelsByType.reduce((list: Channel[], channel: Channel) => {
    if (filterString) {
      if (channel.name?.toLowerCase().includes(filterString.toLowerCase())) {
        list.push(channel);
      }

      list = [
        ...list,
        ...channel.children?.filter((chan) =>
          chan.name?.toLowerCase().includes(filterString.toLowerCase())
        ),
      ];

      return list;
    } else {
      return channelsByType;
    }
  }, []);
};
