import useTundraStore from "../../store/useTundraStore";
import TitleTypePicker from "./TitleTypePicker";

const ConnectedTitleTypePicker = () => {
  const titleId = useTundraStore((state) => state.titleId);
  const setTitleId = useTundraStore((state) => state.setTitleId);

  return <TitleTypePicker value={titleId} onChange={setTitleId} />;
};

export default ConnectedTitleTypePicker;
