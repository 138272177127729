import { Box } from "@mui/material";
import { PropsWithChildren } from "react";

const Toolbar = ({
  actionComponent,
  children,
}: PropsWithChildren<{
  actionComponent?: React.ReactNode;
}>) => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: "48px",
        left: "0px",
        right: "0px",
        zIndex: 40,
        height: "72px",
        backgroundColor: "background.paper",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "12px",
          padding: "16px 30px 16px 17px",
          overflowX: "auto",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {children}
      </Box>
      {actionComponent}
    </Box>
  );
};
export default Toolbar;
