import React, { useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Box, FormControlLabel, Typography } from "@mui/material";
import { filterChannels } from "./filterChannels";
import { Channel } from "../../types/Channel";
import NumberTag from "../NumberTag/NumberTag";

type ChannelsTreeProps = {
  channels: Channel[];
  channelsType: number;
  value: number[];
  onChange: (ids: number[]) => void;
  searchLabel: string;
  allowSelectingOnTopLevel: boolean;
};

const ChannelsTree: React.FC<ChannelsTreeProps> = ({
  channels,
  channelsType,
  value,
  onChange,
  searchLabel,
  allowSelectingOnTopLevel,
}) => {
  const [channelFilter, setChannelFilter] = useState("");
  const filteredChannels = filterChannels(
    channels,
    channelsType,
    channelFilter
  );

  function handleChangeFilter(e: any) {
    setChannelFilter(e.target.value ?? "");
  }

  function handleChannelSelect(e: any) {
    e.stopPropagation();

    if (e.target.checked) {
      onChange([...value, Number(e.target.value)]);
    } else {
      onChange(value.filter((id) => id !== Number(e.target.value)));
    }
  }

  function handleSelectDaughters(e: any, daughters: Channel[]) {
    e.stopPropagation();
    let ids = daughters.map((chan) => chan.id);
    onChange(Array.from(new Set([...value, ...ids])));
  }

  function handleRemoveDaughters(e: any, daughters: Channel[]) {
    e.stopPropagation();
    let ids = daughters.map((chan) => chan.id);
    onChange(value.filter((id) => !ids.includes(id)));
  }

  return (
    <>
      <Box sx={{ padding: "24px" }}>
        <TextField
          size={"small"}
          value={channelFilter}
          onChange={handleChangeFilter}
          fullWidth={true}
          placeholder={searchLabel}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Box>
        {filteredChannels.map((channel: Channel) => {
          return (
            <Accordion
              key={channel.id}
              sx={{
                boxShadow: "none",
                backgroundColor: "background.default",
                "&:before": {
                  display: "none",
                },
              }}
              disableGutters={true}
            >
              <AccordionSummary
                expandIcon={
                  channel.children.length > 0 && (
                    <ExpandMore
                      sx={{ fontSize: "30px", color: "primary.main" }}
                    />
                  )
                }
                sx={{
                  px: "24px",
                  m: 0,
                  "& .MuiAccordionSummary-content": { m: 0 },
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {((!!channelFilter && channel.children.length === 0) ||
                      (allowSelectingOnTopLevel &&
                        (channel.applicationIds || []).length > 0)) && (
                      <Checkbox
                        sx={{ marginRight: "10px", px: 0 }}
                        onClick={handleChannelSelect}
                        value={channel.id}
                        checked={value.includes(channel.id) ?? ""}
                      />
                    )}
                    <Typography fontSize="16px">{channel.name}</Typography>

                    {(() => {
                      const num = channel.children
                        .map((c) => c.id)
                        .filter((id) => value.includes(id)).length;

                      if (num === 0) {
                        return null;
                      }
                      return (
                        <NumberTag
                          sx={{ marginLeft: "10px" }}
                          value={
                            channel.children
                              .map((c) => c.id)
                              .filter((id) => value.includes(id)).length
                          }
                        />
                      );
                    })()}
                  </Box>
                  {channel.children?.length > 0 && (
                    <>
                      {channel.children?.every((c) => value.includes(c.id)) ? (
                        <Button
                          variant="text"
                          sx={{
                            fontSize: "12px",
                            textTransform: "none",
                          }}
                          onClick={(e) =>
                            handleRemoveDaughters(e, channel.children)
                          }
                        >
                          {allowSelectingOnTopLevel
                            ? "Remove daughters"
                            : "Deselect all"}
                        </Button>
                      ) : (
                        <Button
                          variant="text"
                          sx={(theme) => ({
                            fontSize: "12px",
                            textTransform: "none",
                            [theme.breakpoints.down("sm")]: {
                              display: "none",
                            },
                          })}
                          onClick={(e) =>
                            handleSelectDaughters(e, channel.children)
                          }
                        >
                          {allowSelectingOnTopLevel
                            ? "Select daughters"
                            : "Select all"}
                        </Button>
                      )}
                    </>
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0 }}>
                {channel.children?.map((chan) => {
                  return (
                    <Box
                      key={chan.id}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "40px",
                      }}
                    >
                      <FormControlLabel
                        sx={{ "& .MuiTypography-root": { fontSize: "16px" } }}
                        control={
                          <Checkbox
                            style={{ marginRight: 0 }}
                            onClick={handleChannelSelect}
                            value={chan.id}
                            checked={value.includes(chan.id) ?? ""}
                          />
                        }
                        label={chan.name}
                      />
                    </Box>
                  );
                })}
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </>
  );
};

export default ChannelsTree;
