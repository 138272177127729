import { useMemo } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Schedule from "./pages/Schedule/Schedule";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ThemeProvider } from "@mui/material";
import getTheme from "./getTheme";
import { BrowserRouter as Router, Route, useHistory } from "react-router-dom";
import Changes from "./pages/Changes/Changes";

import Grid from "./pages/Grid/Grid";
import "dayjs/locale/en-gb";
import { LoginCallback, Security } from "@okta/okta-react";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import useTundraStore from "./store/useTundraStore";

const queryClient = new QueryClient();

const redirectURL = window.location.origin + "/login/callback";
const logoutRedirectURL = window.location.origin + "/";

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_URL!,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: redirectURL,
  logoutUrl: logoutRedirectURL,
  pkce: true,
});

const AppWithRouterAccess = () => {
  const history = useHistory();
  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    history.replace(toRelativeUrl("/", window.location.origin));
  };

  const colorMode = useTundraStore((state) => state.colorMode);
  const theme = useMemo(() => getTheme(colorMode), [colorMode]);

  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <ThemeProvider theme={theme}>
          <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
            <Route path="/" exact component={Schedule} />
            <Route exact path="/changes" component={Changes} />
            <Route path="/grid" component={Grid} />
            <Route path="/login/callback" component={LoginCallback} />
          </Security>
        </ThemeProvider>
      </LocalizationProvider>
    </QueryClientProvider>
  );
};

const RouterApp = () => (
  <Router>
    <AppWithRouterAccess />
  </Router>
);

export default RouterApp;
