import { useQuery } from "@tanstack/react-query";
import { Classifier } from "../types/Classifier";

export enum ClassifierKind {
  Sport = 0,
  Competition = 1,
  Event = 2,
}

const ELEMENTS_API_URL = process.env.REACT_APP_ELEMENTS_API as string;

const getElements = (kind: ClassifierKind): Promise<Classifier[]> => {
  return fetch(ELEMENTS_API_URL + `${kind}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ start: 0, range: 9999 }),
  }).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
};

const useClassifiers = (kind: ClassifierKind) => {
  return useQuery({
    queryKey: ["elementsData", kind],
    cacheTime: 24 * 60 * 1000,
    queryFn: () => getElements(kind),
  });
};

export default useClassifiers;
