import { SerializedFilter } from "../types/SerializedFilter";
import { TundraFilters } from "../store/useTundraStore";
import dayjs from "dayjs";

export const deserializeFilters = ({
  startDate,
  endDate,
  searchType,
  searchString,
  countries,
  ...rest
}: SerializedFilter): TundraFilters => {
  return {
    pickerStartDate: dayjs(startDate).format("YYYY-MM-DD"),
    pickerEndDate: dayjs(endDate).format("YYYY-MM-DD"),
    searchType: searchType || "all",
    searchString: searchString || "",
    countries: countries || [],
    ...rest,
  };
};
