import { ProgramsOfADay } from "../types/ProgramsOfADay";
import { StartOfDay } from "../types/StartOfDay";
import { add, sub } from "date-fns";
import { Program } from "../types/Program";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(utc);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const normalizeDate = (date: Date, startOfDay: StartOfDay) => {
  return startOfDay === "3am" ? sub(date, { hours: 3 }) : new Date(date);
};

const restoreDate = (date: Date, startOfDay: StartOfDay) => {
  return startOfDay === "3am" ? add(date, { hours: 3 }) : new Date(date);
};

const splitProgramsByStartOfDay = (
  programs: Program[],
  startOfDay: StartOfDay
): Program[] => {
  const result: Program[] = [];

  for (let program of programs) {
    const programStartDate = normalizeDate(program.startDate, startOfDay);
    const programEndDate = normalizeDate(program.endDate, startOfDay);
    if (programStartDate.getUTCDate() !== programEndDate.getUTCDate()) {
      const firstHalf = { ...program };
      const secondHalf = { ...program };

      firstHalf.startDate = programStartDate;
      firstHalf.endDate = new Date(programEndDate);
      secondHalf.startDate = new Date(programStartDate);
      secondHalf.endDate = programEndDate;

      firstHalf.endDate.setUTCHours(23, 59, 59, 0);
      firstHalf.endDate.setUTCDate(firstHalf.startDate.getUTCDate());
      firstHalf.endDate.setUTCMonth(firstHalf.startDate.getUTCMonth());
      firstHalf.endDate.setUTCFullYear(firstHalf.startDate.getUTCFullYear());

      secondHalf.startDate.setUTCHours(0, 0, 0, 0);
      secondHalf.startDate.setUTCDate(secondHalf.endDate.getUTCDate());
      secondHalf.startDate.setUTCMonth(secondHalf.endDate.getUTCMonth());
      secondHalf.startDate.setUTCFullYear(secondHalf.endDate.getUTCFullYear());

      firstHalf.startDate = restoreDate(firstHalf.startDate, startOfDay);
      firstHalf.endDate = restoreDate(firstHalf.endDate, startOfDay);

      secondHalf.startDate = restoreDate(secondHalf.startDate, startOfDay);
      secondHalf.endDate = restoreDate(secondHalf.endDate, startOfDay);

      result.push(firstHalf);
      if (secondHalf.startDate < secondHalf.endDate) {
        result.push(secondHalf);
      }
    } else {
      result.push(program);
    }
  }
  return result;
};

export const getDatesBetween = (startDate: string, stopDate: string) => {
  const start = dayjs(startDate);
  const end = dayjs(stopDate);

  const dates = [];

  for (let date = start; date.isSameOrBefore(end); date = date.add(1, "day")) {
    dates.push(date.format("YYYY-MM-DD"));
  }

  return dates;
};

export const splitProgramsByDay = (
  programs: Program[],
  startDate: string,
  endDate: string,
  startOfDay: StartOfDay
): ProgramsOfADay[] => {
  const programsByDay: { [date: string]: Program[] } = {};

  const separatedPrograms = splitProgramsByStartOfDay(programs, startOfDay);

  for (let program of separatedPrograms) {
    const normalizedStartDate =
      startOfDay === "3am"
        ? sub(program.startDate, { hours: 3 })
        : program.startDate;

    const dayTimestamp = dayjs(normalizedStartDate).utc().format("YYYY-MM-DD");

    programsByDay[dayTimestamp] = programsByDay[dayTimestamp] || [];
    programsByDay[dayTimestamp].push(program);
  }

  const result: ProgramsOfADay[] = [];

  for (let day of getDatesBetween(startDate, endDate)) {
    result.push({
      date: day,
      programs: programsByDay[day] || [],
    });
  }

  result.sort(
    (a, b) =>
      dayjs(a.date).toDate().getTime() - dayjs(b.date).toDate().getTime()
  );

  return result;
};
