import useTundraStore from "../../store/useTundraStore";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  CircularProgress,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { ScheduleProgram } from "../../types/ScheduleProgram";
import useEmissionDetails from "../../hooks/useEmissionDetails";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { getEventColorsAndIcon } from "../../utils/getEventColorsAndIcon";
import getBroadcastUIType from "../../utils/getBroadcastUIType";
import EventIcon from "../../components/EventIcon/EventIcon";
import DecrocheIcon from "../../components/EventIcon/DecrocheIcon";
import { TabContext, TabList } from "@mui/lab";
import { useState } from "react";
import { ProgramDetails } from "../../types/ProgramDetails";
import MulticamIcon from "../../components/EventIcon/MulticamIcon";
import { Close } from "@mui/icons-material";

const Image = styled("img")``;

type TableItem = { label: string; value: string; id: string };
type Field = {
  id: string;
  label: string;
  converterFn?: (v?: string) => string;
};

const getDetailsFields = (isSport: boolean): Field[] => {
  return [
    ...(isSport
      ? [
          { id: "sportingEventName", label: "Sporting Event" },
          { id: "variantName", label: "Variant" },
          { id: "sportName", label: "Sport" },
          { id: "competitionName", label: "Competition" },
          { id: "eventName", label: "Event" },
          { id: "roundName", label: "Round" },
          { id: "disciplineName", label: "Discipline" },
          { id: "genderName", label: "Gender" },
          { id: "ageName", label: "Age" },
        ]
      : []),
    ...(!isSport
      ? [
          { id: "productName", label: "Product Name" },
          { id: "typeName", label: "Type" },
          { id: "groupName", label: "Group" },
          { id: "labelName", label: "Label" },
        ]
      : []),
    { id: "firstBroadcastChannel", label: "First Broadcast Channel" },
    {
      id: "firstBroadcastDate",
      label: "First Broadcast Date",
      converterFn: (v) => (v && dayjs(v).format("YYYY-MM-DD")) || "",
    },
    { id: "countryName", label: "Country" },
    { id: "cityName", label: "City" },
    { id: "players", label: "Players" },
    { id: "comments", label: "Comments" },
    { id: "infosuppName", label: "Info supp" },
    { id: "multicam", label: "Multicam" },
  ];
};

const getTechnicalFields = (isSport: boolean): Field[] => {
  return [
    ...(isSport
      ? [
          { id: "spevId", label: "SPEV Id" },
          { id: "variantId", label: "Variant Id" },
          { id: "sportId", label: "Sport Id" },
          { id: "competitionId", label: "Competition Id" },
          { id: "eventId", label: "Event Id" },
        ]
      : []),
    ...(!isSport ? [{ id: "spevId", label: "Product Id" }] : []),
    { id: "classificationId", label: "Classification Id" },
    { id: "valEmiId", label: "Val Emi Id" },
    { id: "bamBroadcastId", label: "Broadcast Id" },
    { id: "programId", label: "Program Id" },
    { id: "transmissionId", label: "Transmission Id" },
  ];
};

const renderDateTime = (startDate: string, endDate: string) => {
  if (dayjs(startDate).isSame(endDate, "day")) {
    return (
      dayjs(startDate).format("DD/MM/YY, HH:mm — ") +
      dayjs(endDate).format("HH:mm")
    );
  } else {
    return (
      dayjs(startDate).format("DD/MM/YY, HH:mm — ") +
      dayjs(endDate).format("DD/MM/YY, HH:mm")
    );
  }
};

const renderField = (
  { id, label, converterFn }: Field,
  details: ProgramDetails
) => {
  // @ts-ignore
  const rawValue: string | undefined = details[id];
  const processedValue = converterFn ? converterFn(rawValue) : rawValue;
  const value = `${processedValue || "-"}`;
  return { id, label, value };
};

const Content = ({ program }: { program: ScheduleProgram }) => {
  const titleId = useTundraStore((state) => state.titleId);
  const [currentTab, setCurrentTab] = useState("info");
  const detailsRequest = useEmissionDetails(program, titleId);

  if (detailsRequest.isLoading) {
    return <CircularProgress />;
  }

  if (detailsRequest.error || !detailsRequest.data) {
    return <>Error occurred</>;
  }

  const details = detailsRequest.data;

  console.log("program", program);
  console.log("program details", details);

  const [, accentColor, Icon] = getEventColorsAndIcon(
    getBroadcastUIType(program.broadcastTypeId)
  );

  let items: TableItem[] = [];

  if (currentTab === "info") {
    if (
      details.metadatas.playersTeams &&
      details.metadatas.playersTeams?.length > 0
    ) {
      details.players = details.metadatas.playersTeams
        ?.map((pt) => pt.value)
        .join(" - ");
    }

    items = getDetailsFields(details.isSport).map((field) =>
      renderField(field, details)
    );
  }

  if (currentTab === "tech") {
    items = getTechnicalFields(details.isSport).map((field) =>
      renderField(field, details)
    );
  }

  if (currentTab === "commentators") {
    items = (details.commentators || []).map(
      ({ language, commentator }, i) => ({
        id: `${i}`,
        label: language,
        value: commentator,
      })
    );
  }

  if (currentTab === "audios") {
    items = (details?.metadatas?.audios || [])
      .filter(
        (a) =>
          a.type?.toLowerCase() === "confirmed" ||
          a.type?.toLowerCase() === "native"
      )
      .map(({ key, value, type }) => ({
        id: key,
        label: value,
        value: "",
      }));
  }

  if (currentTab === "affiliates") {
    items = (details?.metadatas?.affiliates || []).map(
      ({ key, value, type }) => ({
        id: key,
        label: value,
        value: "",
      })
    );
  }

  if (currentTab === "countries") {
    items = (details?.metadatas?.countries || []).map(({ key, value }) => ({
      id: key,
      label: value,
      value: "",
    }));
  }

  if (currentTab === "externalEvents") {
    items = (details?.metadatas?.externalEvents || []).map(
      ({ key, value, type }) => ({
        id: key,
        label: `${type}:${value}`,
        value: `${key}`,
      })
    );
  }

  if (currentTab === "territories") {
    items = (details?.metadatas?.territories || []).map(({ key, value }) => ({
      id: key,
      label: value,
      value: "",
    }));
  }

  return (
    <>
      <Typography
        fontSize="18px"
        fontWeight="700"
        color="primary.main"
        mt="6px"
      >
        {details.title}
      </Typography>
      <Typography
        fontSize="15px"
        fontWeight="400"
        color="primary.main"
        mt="6px"
      >
        {renderDateTime(details.startDate, details.endDate)}
      </Typography>
      <Box
        sx={{
          display: "flex",
          mt: "16px",
          gap: "16px",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <Image
            src={details.picture}
            sx={{
              maxWidth: "162px",
            }}
          />
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          {Icon && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="8px"
            >
              <EventIcon
                background={accentColor}
                Icon={Icon}
                isActive
              ></EventIcon>
              <Typography color="primary.main" fontWeight="700" fontSize="14px">
                {details.broadcastType}
              </Typography>
            </Box>
          )}
          {program.decro !== "Default" && program.channelIsPlayer === false && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="8px"
            >
              <EventIcon
                background={accentColor}
                Icon={DecrocheIcon}
                isActive
              />
              <Typography color="primary.main" fontWeight="700" fontSize="14px">
                {program.decro}
              </Typography>
            </Box>
          )}
          {program.isMultiCam && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap="8px"
            >
              <EventIcon
                background={accentColor}
                Icon={MulticamIcon}
                isActive
              />
              <Typography color="primary.main" fontWeight="700" fontSize="14px">
                Multicam: {details.multicam}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <TabContext value={currentTab}>
        <Box>
          <TabList onChange={(_, value) => setCurrentTab(value)}>
            <Tab value="info" label="Informations" />
            <Tab value="tech" label="Technical" />{" "}
            {details?.commentators.length > 0 && (
              <Tab value="commentators" label="Commentators" />
            )}
            {details?.metadatas?.countries &&
              details.metadatas.countries.length > 0 && (
                <Tab value="countries" label="Countries" />
              )}
            {details?.metadatas?.affiliates &&
              details.metadatas.affiliates.length > 0 && (
                <Tab value="affiliates" label="Affiliates" />
              )}
            {details?.metadatas?.audios &&
              details.metadatas.audios.length > 0 && (
                <Tab value="audios" label="Audios" />
              )}
            {details?.metadatas?.externalEvents &&
              details.metadatas.externalEvents.length > 0 && (
                <Tab value="externalEvents" label="External Events" />
              )}
            {details?.metadatas?.territories &&
              details.metadatas.territories.length > 0 && (
                <Tab value="territories" label="Territories" />
              )}
          </TabList>
        </Box>
      </TabContext>
      <TableContainer
        component={Box}
        sx={{
          mt: "16px",
          maxHeight: "50vh",
          overflow: "auto",
          maxWidth: "75vw",
        }}
      >
        <Table sx={{ maxWidth: "75vw" }} size="small">
          <TableBody key={currentTab}>
            {items.map(({ label, value }, i) => (
              <TableRow
                key={`${label}-${value}`}
                sx={(theme) => ({
                  backgroundColor:
                    i % 2 === 1
                      ? theme.palette.mode === "light"
                        ? "gray.50"
                        : "background.paper"
                      : "transparent",
                })}
              >
                <TableCell>
                  <Typography color="primary.text" fontWeight="700">
                    {label}
                  </Typography>
                </TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  <Typography color="primary.text" fontWeight="700">
                    {value}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const ProgramDetailsModal = () => {
  const selectedProgram = useTundraStore((state) => state.selectedProgram);

  const setSelectedProgram = useTundraStore(
    (state) => state.setSelectedProgram
  );

  const onClose = () => {
    setSelectedProgram();
  };

  if (selectedProgram) {
    return (
      <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Program Informations
          <IconButton onClick={onClose}>
            <Close sx={{ color: "primary.main", fontSize: "22px" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Content program={selectedProgram} />
        </DialogContent>
      </Dialog>
    );
  }

  return <></>;
};

export default ProgramDetailsModal;
