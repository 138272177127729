import { useQuery } from "@tanstack/react-query";
import { ScheduleProgram } from "../types/ScheduleProgram";
import { toDate } from "date-fns-tz";
import { StartOfDay } from "../types/StartOfDay";
import { UIChannel, UIClassicChannel } from "../types/UIChannel";
import { ChannelType } from "../types/ChannelType";
import { ProgramType } from "../types/ProgramType";
import dayjs from "dayjs";

const EMISSIONS_API_URL = process.env.REACT_APP_EMISSIONS_API as string;

const parameters = {
  sportIds: [],
  competIds: [],
  eventIds: [],
  countryIds: [],
  searchType: 0,
  searchWords: "",
  broadcastingTypeIds: [1, 2, 3, 4, 5, 6, 7, 8, 9, 11],
};

const formatAffiliates = (channels: UIChannel[]) => {
  const slotsById: Map<number, number[]> = new Map();

  for (let channel of channels) {
    if (channel.type === ChannelType.Affiliate) {
      const slotsForId = slotsById.get(channel.id) || [];
      slotsForId.push(channel.slotId);
      slotsById.set(channel.id, slotsForId);
    }
  }

  const result: { id: number; slotIds: number[] }[] = [];

  slotsById.forEach((slotIds, id) => {
    result.push({
      id,
      slotIds,
    });
  });

  return result;
};

const getEmissions = (
  showAffiliates: boolean,
  startDate: string,
  endDate: string,
  channels: UIChannel[],
  titleId: number,
  startOfDay: StartOfDay
): Promise<ScheduleProgram[]> => {
  const start = `${startDate}T00:00:00.000Z`;
  const end = `${endDate}T00:00:00.000Z`;

  const numberOfDays =
    dayjs(end).diff(dayjs(start), "day") + (startOfDay === "3am" ? 1 : 0);

  const numberOfChannels = channels.length;
  const endDateToRequest =
    startOfDay === "3am"
      ? dayjs(endDate).add(2, "day")
      : dayjs(endDate).add(1, "day");

  return fetch(EMISSIONS_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      ...parameters,
      activeTab: showAffiliates ? "Affiliates" : "Schedule",
      startDate: start,
      endDate: `${endDateToRequest.format("YYYY-MM-DD")}T00:00:00.000Z`,
      pagination: {
        nbColumnPerPage: (numberOfDays + 3) * numberOfChannels,
        Next: true,
      },
      channelLanguageIds: showAffiliates
        ? [{ channelId: 0, languageId: 0 }]
        : channels
            .filter((channel) => channel.type === ChannelType.Channel)
            .map((channel) => ({
              channelId: channel.id,
              languageId: (channel as UIClassicChannel).languageId,
            })),
      supportId: titleId,
      slotIds: [],
      affiliateLanguageId: 0,
      affiliates: showAffiliates ? formatAffiliates(channels) : undefined,
    }),
  })
    .then((res) => {
      if (!res.ok) {
        throw new Error(res.statusText);
      }
      return res.json();
    })
    .then((programs) => {
      return programs.map(
        ({
          startDate,
          endDate,
          emissionId,
          broadcastId,
          ...data
        }: {
          startDate: string;
          endDate: string;
          emissionId: number;
          broadcastId: string;
        }) => {
          const startDateObject = toDate(startDate, { timeZone: "UTC" });
          const endDateObject = toDate(endDate, { timeZone: "UTC" });
          return {
            startDate: startDateObject,
            endDate: endDateObject,
            displayStartDate: startDateObject,
            displayEndDate: endDateObject,
            type: ProgramType.Schedule,
            ...data,
          };
        }
      ) as ScheduleProgram[];
    })
    .then((programs) =>
      programs.sort((a, b) => a.startDate.getTime() - b.startDate.getTime())
    )
    .then((programs) => {
      return programs.map((program, i) => {
        return { ...program, key: `program_${i}` };
      });
    });
};

export const useEmissions = (
  showAffiliates: boolean,
  startDate: string,
  endDate: string,
  channels: UIChannel[],
  titleId: number,
  startOfDay: StartOfDay
) => {
  return useQuery({
    queryKey: [
      "emissionsData",
      showAffiliates,
      startDate,
      endDate,
      JSON.stringify(channels),
      startOfDay,
      titleId,
    ],
    queryFn: () =>
      getEmissions(
        showAffiliates,
        startDate,
        endDate,
        channels,
        titleId,
        startOfDay
      ),
  });
};
