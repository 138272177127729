import { Box, styled } from "@mui/material";
import { PropsWithChildren } from "react";

export const ScaleContainer = styled("div")`
  position: absolute;
  top: 78px;
  left: 0;
  bottom: 0;
  overflow: hidden;
  width: 50px;
`;

export const EmissionsContainer = styled("div")`
  top: 78px;
  left: 50px;
  right: 0;
  bottom: 0;
  position: absolute;
`;

export const BlockHeader = styled("div")(({ theme }) => ({
  position: "sticky",
  display: "block",
  color: theme.palette.text.primary,
  left: 0,
  right: 0,
  top: 0,
  height: 25,
  zIndex: 19,
  borderBottom: `1px solid ${theme.palette.divider}`,
  background: theme.palette.background.default,
}));

export const Items = styled("div")`
  display: flex;
`;

export const Item = ({
  width,
  children,
}: PropsWithChildren<{ width: number }>) => {
  return (
    <Box sx={{ position: "relative", width: `${width}px` }}>{children}</Box>
  );
};

export const ItemHeader = styled("div")(({ theme }) => ({
  margin: "0 0 12px 0",
  position: "sticky",
  top: "25px",
  background: theme.palette.background.default,
  padding: "0 0 3px 0",
  zIndex: "19",
  width: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

export const LoadingOrErrorContainer = styled("div")({
  position: "fixed",
  top: "200px",
  left: "0",
  right: "0",
  display: "flex",
  justifyContent: "space-around",
});
