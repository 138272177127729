import { Channel } from "../types/Channel";

const getChannelByAffiliateId = (
  affiliateId: number,
  slotId: number,
  channels: Channel[] = []
): Channel | undefined => {
  for (let channel of channels) {
    if (channel.affiliateId === affiliateId && channel.slotId === slotId) {
      return channel;
    }
    if (channel.children) {
      const innerChannel = getChannelByAffiliateId(
        affiliateId,
        slotId,
        channel.children
      );
      if (innerChannel) {
        return innerChannel;
      }
    }
  }
};

export default getChannelByAffiliateId;
