import { useQuery } from "@tanstack/react-query";
import { Language } from "../types/Language";

const LANGUAGES_API_URL = process.env.REACT_APP_LANGUAGES_API as string;

const getLanguages = (): Promise<Language[]> => {
  return fetch(LANGUAGES_API_URL).then((res) => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
};

const useLanguages = () => {
  return useQuery({
    queryKey: ["languagesData"],
    cacheTime: 24 * 60 * 1000,
    queryFn: () => getLanguages(),
  });
};

export default useLanguages;
