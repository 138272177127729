import { SVGProps } from "react";

const ReplayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.818 2.182H2.182C.97 2.182 0 3.152 0 4.364v13.09a2.182 2.182 0 0 0 2.182 2.182h5.454v2.182h8.728v-2.182h5.454A2.182 2.182 0 0 0 24 17.455V4.364a2.182 2.182 0 0 0-2.182-2.182Zm0 15.273H2.182V4.364h19.636m-5.454 6.545-7.637 4.364V6.545"
      fill="white"
    />
  </svg>
);

export default ReplayIcon;
